import styled from "styled-components";
import { Input, Steps, Select, Slider } from "antd";

const { Option } = Select;

import * as ResponsiveBreakpoint from "../../responsiveBreakpoints";

export const Heading = styled.h1`
  font-family: Athletics;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
  margin: 0;
  span {
    color: #ef2c5a;
  }

  @media(max-width: 980px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  padding: 40px;
  width: 100%;
  position: fixed;
`;

export const Content = styled.div`
  display: flex;
  gap: 70px;
  margin-top: 50px;
  width: 100%;
  position: relative;

  @media(max-width: 980px) {
    flex-direction: column;
    margin-top: 0;
    gap: 32px;
  }
`;

export const ContentWrapper = styled.div`
  width: 45%;
  max-height: 70vh;
  overflow-y: scroll;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
  &::-webkit-scrollbar {
    display: none; 
  }

  @media(max-width: 980px) {
    width: 100%; 
  }
`;

export const PendingStepsCount = styled.p`
  margin-right: 0;
  position: absolute;
  right: 64px;
  background-color: #fff;
  padding-bottom: 1rem;
  padding-left: 1rem;

  @media(max-width: 980px) {
    display: none;
  }
`

export const FormInputDiv = styled.div`
  display: block;
  width: 100%;
  margin: 18px 0 32px;
  font-family: "Inter";

  & > span {
    font-weight: 400;
    font-size: 10px;
    line-height: 15.6px;
    margin-top: 4px;
    color: var(--primary-pink);
  }
  @media (min-width: 768px) {
    margin: 18px 0 22px;
  }
`;

type FormFlexProp = {
  marginBottom?: boolean;
}
export const FormsFlex = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  justify-content: center;
  margin-bottom: ${(prop: FormFlexProp) => prop.marginBottom ?? true ? '24px' : '0px'};

  @media(max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const OverallContainer = styled.div`
  margin-bottom: 7rem;
`

export const PageHeaderWrapper = styled.div`
  margin-bottom: 32px;
`

export const BusinessTitle = styled.h1`
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;

  @media(max-width:980px) {
    font-family: Athletics;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
`;

export const BusinessSectionTitle = styled.h2`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
`;

type Props = {
  alignLeft?: boolean;
}
export const BusinessText = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;

  @media(max-width:980px) {
    text-align: ${(props: Props) => props.alignLeft ? 'left' : 'center'}
  }
`;

type SectionProps = {
  $marginBottom?: boolean;
}

export const Section = styled.div`
  position: relative;
  margin-bottom: ${(props: SectionProps) => props.$marginBottom ?? true ? '24px' : '0'};
  & > p {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 180%;
    color: #000000;
    color: red;
  }

  .business-logo-copy {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    margin-top: 32px;
  }
  .business-logo-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: left;
    color: #818691;
  }

  @media(max-width: 768px) {
    margin-bottom: 16px;
  }
`;

export const ErrorText = styled.div`
  display: flex;
  margin-top: 4px;
  align-items: center;

  & span {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: var(--primary-pink);
    text-align: left;
    margin-left: 8px;
  }
`;

export const Container = styled.div`
  @media${ResponsiveBreakpoint.largerThanDesktop} {
    background: linear-gradient(90deg, #ffffff 50%, #000000 50%);
  }
`;

export const OnboardingWrapper = styled.div`
  background: #ffffff;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
`;

export const OnboardingButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 16px;
`

export const BodyDiv = styled.div`
  padding-left: 50px;

  @media(max-width:980px) {
    padding-right: 50px;
  }

  @media(max-width:768px) {
    padding: 0 16px;
  }
`;

export const TopDiv = styled.div`
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width:768px) {
    padding: 50px 16px;
  }
`;

export const GoToDashboard = styled.div`
  color: #000000;
  border: 1px solid #E9E9E9;
  padding: 12px;
  width: max-content;
  border-radius: 8px;
  cursor: pointer;

  @media(max-width:980px) {
    display: none;
  }
`

export const BtnWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const StepWrapper = styled(Steps)`
  .ant-steps-item-wait .ant-steps-item-icon {
    background: #fff;
    color: #000000;
    border: 1px solid #e9e9e9;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background-color: #fff;
    border: 1px solid #ef2c5a;
    color: #000 !important;
  }

  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #000 !important;
  }

  .ant-steps-item-icon .ant-steps-icon {
    color: #000;
  }

  .ant-steps-item-process .ant-steps-item-title {
    font-weight: bold;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background: #ef2c5a;
    color: #fff !important;
  }

  .ant-steps-item-finish .ant-steps-item-title {
    color: #8f8f8f !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #fff !important;
    display: block;
  }

   .ant-steps-icon {
    display: inline-block;
    color: #fff;
  }

  .ant-steps-item-tail {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 2px;
    height: 100%;
    padding: 0;
  }

  .ant-steps-item-tail::after {
    display: inline-block;
    width: 100%;
    height: 100%;
    background: #e9e9e9;
    border-radius: 1px;
    transition: background 0.3s;
    content: "";
  }

  .ant-steps-item-process .ant-steps-item-tail::after {
    background: #ef2c5a;
  }

  .ant-steps-item-finish .ant-steps-item-tail::after {
    background: #ef2c5a;
    border: 0.5px solid #ef2c5a;
  }

  .ant-steps-item-process .ant-steps-item-tail::after,
  .ant-steps-item-process ~ .ant-steps-item .ant-steps-item-tail::after {
    background: #e9e9e9;
  }

  @media(max-width: 980px) {
    display: none;
  }
`;

export const SocialWrapper = styled(Input)<{ error?: boolean }>`
  display: flex;
  position: relative;
  width: 100%;
  border: 1px solid ${({ error }) => (error ? "red" : "#E9E9E9")};
  border-radius: 10px;
  background-color: transparent;
  height: 48px;

  &:hover {
    border-color: #a3a3a3;
  }

  input {
    font-family: "Inter";
    padding: 12px 12px;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    outline: none;

    &::placeholder {
      color: #565c69 !important;
      font-weight: 400;
      padding: 8px 12px;
      font-size: 14px;
      line-height: 14px;
    }

    &::disabled {
      color: #a3a3a3 !important;
      background: red !important;
    }

    &:focus {
      box-shadow: none;
      border-color: transparent;
    }
  }

  .ant-input-group-addon {
    padding: 8px 12px;
    border: none;
    border-right: 1px solid #E9E9E9;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    box-sizing: border-box;
    background-color: transparent;
    outline: none;
  }

  .ant-select-selector {
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #000000;
  }

  .ant-select-focused .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    color: #000000;
  }

  .ant-select-arrow {
    color: inherit;
  }

  .ant-select-focused {
    outline: none !important;
  }
`;

export const Dropdown = styled(Select)``;

export const DropdownItem = styled(Option)``;

export const ItemFlex = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`;

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  margin-bottom: 15px;
`;
export const DirectorIndex = styled.h1`
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .uploaded-content {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    margin-top: 10px;
    margin: 0;
  }
  .uploaded-copy {
    color: #007912;
    font-weight: 400;
  }
`;

export const OverlaySettlementCurrency = styled.div`
  position: absolute;
  top: 2px;
  right: 0;
`

export const Space = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DocumentUploadBox = styled.div`
  border: 1px solid #E9E9E9;
  padding: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  margin: auto;
  border-radius: 8px;
  height: 82px;

  @media (max-width: 394px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

export const UploadButton = styled.button`
  display: flex;
  align-items: flex-start;
  gap: 10px;

  h4 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    margin-bottom: 0;
  }

  span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #a39d9e;
    display: flex;
    flex-position: straght;
  }`


export const KlashaSpanHighlight = styled.span`

`

type AcknowledgementProp = {
  align?: string;
  marginTop?: string;
}
export const Acknowledgement = styled.div`
  display: flex;
  align-items: ${(prop: AcknowledgementProp) => prop.align || 'center'};
  gap: 8px;
  margin-bottom: 32px;
  margin-top: ${(prop: AcknowledgementProp) => prop.marginTop || '0'};
  font-size: 12px;

  input {
    margin-top: ${(prop: AcknowledgementProp) => prop.align ? '3px' : ''};
  }
`

export const DeclarationText = styled.p`
  color: #5F5F5F;
  font-size 16px;
  margin: 32px 0;
  font-family: Inter;

  span {
    color: #000000;
    font-weight: 600;
  }
`

export const DeclarationWrapper = styled.div`
  width: 100%;
`

export const AdditionalDetailsContainer = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  font-family: Inter;
  margin-bottom: 7rem;
`

export const AdditionalDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
`

export const BizDocBizTypeSelect = styled.div`
  margin: 32px 0;
`

export const Divider = styled.div`
  width: 100%;
  border: 0.5px solid #E9E9E9;
  margin: 32px 0;
`

export const BusinessSecondTitle = styled.h3`
  font-size: 16px;
  margin: 24px 0 0px;
  font-family: Inter;
`

export const DirectorDetailsContainer = styled.div`
  margin-bottom: 60px;
`

export const InnerContainerDirectorDocs = styled.div`
  margin-top: 32px;
`

export const StyledSlider = styled(Slider)`
  display: none;

  .ant-slider-rail {
    background-color: #E9E9E9; 
  }

  .ant-slider-track {
    background-color: #EF2C5A !important; 
  }

  .ant-slider-handle {
    background-color: #EF2C5A !important;
    border-color: #EF2C5A; 
  }

  @media(max-width:980px) {
    display: block;
  }
`;