import React, {useContext, useState} from "react";
import { usePostHog } from 'posthog-js/react'
import {
  TopCard,
  BottomCard,
  ImageBox,
  TextBox,
  Lines,
  Logo,
  Visa,
  ListItems,
} from "../style";
import logo from "../../../assets/icons/logo-white.svg";
import visa from "../../../assets/icons/visa.svg";
import lines from "../assets/lines.png";
import { Button } from "../../../components/common/Button";
import { lists } from "../staticData";
import { setMerchantBusiness, updateCardStage } from "../CardSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Steps from "./steps";
import CreateWallet from "./CreateWallet";
import CardReady from "./CardReady";
import { ListProps } from "../types";
import { getMerchant } from "../CardsApi";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";

const EmptyCard = () => {
  const posthog = usePostHog();
  const dispatch = useAppDispatch();
  const [createWallet, setCreateWallet] = useState(false);
  const { cardStage, wallets } = useAppSelector((state) => state.cards);
  const { business } =
  useAppSelector((state) => state.settings) ?? {};

  const { userObj } = useAppSelector((state) => state.users);

  const ngnWallet = wallets ? wallets.find((w) => w.currency === "NGN") : {};


  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext,
);

  const handleSetupCard = async (wallet) => {
    if (business && business?.approved === false) {
        onShowErrorAlert(
            Error,
            "Business awaiting approval.",
        );
    }

    else if(wallet){
      dispatch(updateCardStage("steps"));
      posthog?.capture("initiate_card_creation_b2b", { status: "success"})

      try {
        const data = await getMerchant(userObj.id);
  
        dispatch(setMerchantBusiness(data));
      } catch (err) {
        console.error(err);
      }
    } else {
      setCreateWallet(true)
      posthog?.capture("initiate_card_creation_b2b", { status: "failure", failure_reason: "No NGN wallet"})
    }
  };

  return (
    <div>
      {cardStage === "empty" && (
        <div>
          <TopCard>
            <ImageBox>  
              <Logo>
                <img src={logo} alt="logo"/>
                <p>Black virtual card</p>
              </Logo>
              <Visa src={visa} alt="visa-card" />
              <Lines src={lines} alt="lines" />
            </ImageBox>

            <TextBox>
              <h1>Let’s setup a USD card for you</h1>
              <span>
                Instantly create a virtual USD card to make online payments easily on all your
                favourite platforms. Available for NGN businesses only.
              </span>
              <Button
                label="Get yours now"
                theme="primary"
                width="146px"
                height="48px"
                fontSize="14px"
                onClick={() =>handleSetupCard(ngnWallet)}
              />
            </TextBox>
          </TopCard>

          <BottomCard>
            {lists.map((list: ListProps) => (
              <ListItems key={list.id}>
                {list.icon}
                <div>
                  <p>{list.title}</p>
                  <span>{list.subText}</span>
                </div>
              </ListItems>
            ))}
          </BottomCard>
        </div>
      )}

      {createWallet && <CreateWallet setCreateWallet={setCreateWallet} />}

      {cardStage === "steps" && <Steps />}

      {cardStage === "cardReady" && <CardReady />}
    </div>
  );
};

export default EmptyCard;
