import { Button } from "../../../../../../components/common/Button";
import { ItemWrapper, ContentWrapper, Error, InputWrapper } from "../styles";
import { savedCNYData } from "../../../../redux/formDataSlice";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import CustomSelect from "../../../../../../components/common/CustomSelect";
import React, { useEffect } from "react";
import { Input } from "../../../../../../components/common/Input";
import {BENEFICIARY_OPTIONS, CNYIDTypes, Relationships} from "../../../../static";
import { InputPhone } from "../../../../../../components/common/InputPhone";
import CountrySelect from "../../../../../../components/common/CountrySelect";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";

type Props = {
  handleNext?: () => void;
  handlePrevious?: () => void;
};

type FormInputs = {
  beneficiary: string;
  beneficiaryBusinessLegalName: string;
  beneficiaryBusinessNumber: string;
  beneficiaryID: string;
  beneficiaryIdNumber: string;
  beneficiaryStreet: string;
  beneficiaryPhoneNumber: string;
  beneficiaryCity: string;
  beneficiaryCountry: string;
  beneficiaryPostcode: string;
  beneficiaryFirstName: string;
  beneficiaryLastName: string;
  beneficiaryEmail: string;
  relationship: string;
};

const Beneficiary = ({ handleNext, handlePrevious }: Props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormInputs>();
  const getSavedData = useAppSelector((state) => state.formData.cnyData);
  const dispatch = useAppDispatch();

  const { paymentMethod } = getSavedData || {};


  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    dispatch(savedCNYData(data));
    handleNext?.();
  };

  useEffect(() => {
    if (getSavedData) {
      Object.keys(getSavedData).forEach((key) => {
        setValue(
          key as keyof FormInputs,
          getSavedData[key as keyof FormInputs],
        );
      });
    }
  }, [getSavedData, setValue]);


  return (
    <ContentWrapper>
        <InputWrapper>
            <Controller
                name="beneficiary"
                control={control}
                rules={{ required: "Beneficiary type is required" }}
                render={({ field }) => (
                    <CustomSelect
                        value={field.value}
                        options={BENEFICIARY_OPTIONS
                            .filter(option => paymentMethod !== "bank_account_business" || option.value === "company")
                            .map(option => ({
                                label: option.label,
                                value: option.value,
                            }))}
                        onChange={field.onChange}
                        $width="100%"
                        placeholder="Beneficiary type"
                    />
                )}
            />
            {<Error>{errors?.beneficiary?.message}</Error>}
        </InputWrapper>

      <InputWrapper>
        <Controller
          name="relationship"
          control={control}
          rules={{ required: "Relationship is required" }}
          render={({ field }) => (
            <CustomSelect
              value={field.value}
              options={Relationships}
              onChange={field.onChange}
              $width="100%"
              placeholder="Relationship"
            />
          )}
        />
        {<Error>{errors?.relationship?.message}</Error>}
      </InputWrapper>

      {paymentMethod === "bank_account_business" && (
        <InputWrapper>
          <Controller
            name="beneficiaryBusinessLegalName"
            control={control}
            rules={{ required: "Legal name is required" }}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={field.onChange}
                hasError={!!errors.beneficiaryBusinessLegalName}
                label="Business legal name"
              />
            )}
          />
          {<Error>{errors?.beneficiaryBusinessLegalName?.message}</Error>}
        </InputWrapper>
      )}

      <ItemWrapper>
        <InputWrapper>
          <Controller
            name="beneficiaryID"
            control={control}
            rules={{ required: "ID type is required" }}
            render={({ field }) => (
              <CustomSelect
                value={field.value}
                options={CNYIDTypes}
                onChange={field.onChange}
                $width="100%"
                placeholder="ID Type"
              />
            )}
          />
          {<Error>{errors?.beneficiaryID?.message}</Error>}
        </InputWrapper>
        <InputWrapper>
          <Controller
            name="beneficiaryIdNumber"
            control={control}
            rules={{ required: "ID number is required" }}
            render={({ field }) => (
              <Input
                type="text"
                label="ID Number"
                value={field.value}
                hasError={!!errors.beneficiaryIdNumber}
                onChange={field.onChange}
              />
            )}
          />
          {<Error>{errors?.beneficiaryIdNumber?.message}</Error>}
        </InputWrapper>
      </ItemWrapper>

      <ItemWrapper>
        <InputWrapper>
          <Controller
            name="beneficiaryFirstName"
            control={control}
            rules={{ required: "First name is required" }}
            render={({ field }) => (
              <Input
                type="text"
                label="First name"
                value={field.value}
                onChange={field.onChange}
                hasError={!!errors.beneficiaryFirstName}
                labelColor="#FFFFFF"
              />
            )}
          />
          {<Error>{errors?.beneficiaryFirstName?.message}</Error>}
        </InputWrapper>

        <InputWrapper>
          <Controller
            name="beneficiaryLastName"
            control={control}
            rules={{ required: "Last name is required" }}
            render={({ field }) => (
              <Input
                type="text"
                label="Last name"
                value={field.value}
                onChange={field.onChange}
                hasError={!!errors.beneficiaryLastName}
                labelColor="#FFFFFF"
              />
            )}
          />
          {<Error>{errors?.beneficiaryLastName?.message}</Error>}
        </InputWrapper>
      </ItemWrapper>

      <InputWrapper>
        <Controller
          name="beneficiaryPhoneNumber"
          control={control}
          rules={{ required: "Phone number is required" }}
          render={({ field }) => (
            <InputPhone
              label="Phone number"
              field={field}
              defaultCountry="CN"
            />
          )}
        />
        {<Error>{errors?.beneficiaryPhoneNumber?.message}</Error>}
      </InputWrapper>

      <InputWrapper>
        <Controller
          name="beneficiaryEmail"
          control={control}
          rules={{
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Please enter a valid email address",
            },
          }}
          render={({ field }) => (
            <Input
              type="text"
              label="Email address"
              value={field.value}
              hasError={!!errors.beneficiaryEmail}
              onChange={field.onChange}
            />
          )}
        />
        {<Error>{errors?.beneficiaryEmail?.message}</Error>}
      </InputWrapper>

      <InputWrapper>
        <Controller
          name="beneficiaryStreet"
          control={control}
          rules={{ required: "Street address is required" }}
          render={({ field }) => (
            <Input
              type="text"
              label="Street address"
              value={field.value}
              hasError={!!errors.beneficiaryStreet}
              onChange={field.onChange}
            />
          )}
        />
        {<Error>{errors?.beneficiaryStreet?.message}</Error>}
      </InputWrapper>

        {getSavedData?.paymentMethod === "bank_account_business" && (
            <InputWrapper>
                <Controller
                    name="beneficiaryBusinessNumber"
                    control={control}
                    rules={{ required: "Business number required" }}
                    render={({ field }) => (
                        <Input
                            type="text"
                            label="Beneficiary business number"
                            value={field.value}
                            hasError={!!errors.beneficiaryBusinessNumber}
                            onChange={field.onChange}
                        />
                    )}
                />
                {<Error>{errors?.beneficiaryBusinessNumber?.message}</Error>}
            </InputWrapper>
        )}

      <InputWrapper>
        <Controller
          name="beneficiaryCity"
          control={control}
          rules={{ required: "City is required" }}
          render={({ field }) => (
            <Input
              type="text"
              label="City"
              value={field.value}
              hasError={!!errors.beneficiaryCity}
              onChange={field.onChange}
            />
          )}
        />
        {<Error>{errors?.beneficiaryCity?.message}</Error>}
      </InputWrapper>

      <ItemWrapper>
        <InputWrapper>
          <Controller
            name="beneficiaryCountry"
            control={control}
            rules={{ required: "Country is required" }}
            render={({ field }) => (
              <InputWrapper>
                <CountrySelect
                  value={field.value}
                  onChange={field.onChange}
                  allCountries={true}
                  placeholder="Country"
                />
              </InputWrapper>
            )}
          />
          {<Error>{errors?.beneficiaryCountry?.message}</Error>}
        </InputWrapper>

        <InputWrapper>
          <Controller
            name="beneficiaryPostcode"
            control={control}
            rules={{ required: "Postcode is required" }}
            render={({ field }) => (
              <Input
                type="text"
                label="Postcode"
                value={field.value}
                onChange={field.onChange}
                hasError={!!errors.beneficiaryPostcode?.type}
              />
            )}
          />
          {<Error>{errors?.beneficiaryPostcode?.message}</Error>}
        </InputWrapper>
      </ItemWrapper>

      <ItemWrapper>
        <Button
          onClick={handlePrevious}
          label="Prev"
          height="48px"
          theme="alternate"
          width="203px"
        />

        <Button
          onClick={handleSubmit(onSubmit)}
          label="Continue"
          height="48px"
        />
      </ItemWrapper>
    </ContentWrapper>
  );
};

export default Beneficiary;
