import styled from "styled-components";
import DatePicker from "../defaults/Datepicker";

const { RangePicker } = DatePicker;

export const Wrapper = styled(RangePicker)`
  width: min(285px, 100%);
  height: 38px;
  border: 1px solid #a3a3a3 !important;
  border-radius: 8px;
  box-shadow: none;

  :hover {
    border-color: #a3a3a3 !important;
  }

  & div[class="ant-picker-range"] {
    display: none !important;
  }

  & div[class="ant-picker-input"] > input {
    font-family: "Inter";
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
  }

  & div[class="ant-picker-input"] > input:placeholder-shown {
    font-weight: 400;
    color: #565c69 !important;
  }

  & div[class="ant-picker-input"] > input::placeholder {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #565c69 !important;
  }

  & > div[class="ant-select-selector"] {
    height: 100% !important;
    border-radius: 8px !important;
    border: none !important;
    box-shadow: none !important;
  }

  & div[class~="ant-picker-active-bar"] {
    background-color: #000000;
  }
`;

export const DatePickerContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const Label = styled.label`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #565c69;
  transition: all 0.3s ease;
  pointer-events: none;
  z-index: 1;
  
  &.active {
    top: 0;
    font-size: 12px;
    background-color: white;
    padding: 0 4px;
  }
`;

type Props = {
  $lightBorder: boolean;
}
export const DateWrapper = styled(DatePicker)`
  width: 100%;
  height: 48px;
  border: ${(props: Props) => (props.$lightBorder ? '1px solid #E9E9E9' : '1px solid #a3a3a3')};
  border-radius: 10px;

  &:hover {
    border-color: #a3a3a3 !important;
  }

  & div[class="ant-picker-input"] > input {
    font-family: "Inter";
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.02em;
  }

  & div[class="ant-picker-input"] > input:placeholder-shown {
    font-weight: 400;
    color: #565c69 !important;
  }

  & div[class="ant-picker-input"] > input::placeholder {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #565c69 !important;
  }

  & > div[class="ant-select-selector"] {
    height: 100% !important;
    border-radius: 8px !important;
    border: none !important;
    box-shadow: none !important;
  }

  & div[class~="ant-picker-active-bar"] {
    background-color: #000000;
  }
`;
