import React, { useContext, useEffect } from "react";
import { BusinessText, BusinessTitle, PageHeaderWrapper, OnboardingButtonContainer } from "../styles";
import { Button } from "../../../components/common/Button";
import { Controller, useForm } from "react-hook-form";
import FormField from "../../../components/FormField/FormField";
import FormSection from "../components/FormSection";
import { InputPhone } from "../../../components/common/InputPhone";
import SocialMediaPicker from "../components/SocialMediaPicker";
import { BusinessContact } from "../Types";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { addBusinessContact, getParticularStep } from "../redux/actions";
import Loader from "../../../components/common/Loader";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import { useWindowSize } from "@reach/window-size";


type Props = {
  handleNext: () => void;
  handlePrevious: () => void;
};

const BusinessContactDetails = ({ handleNext, handlePrevious }: Props) => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize()
  const { businessId, userObj } = useAppSelector((state) => state.users);
  const { loading } = useAppSelector((state) => state.onboarding || {});

  const { onShowAlert: onShowErrorAlert } =
    useContext(NetworkErrorAlertContext) || {};

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
  } = useForm<BusinessContact>({ 
    mode: 'onChange',
    defaultValues: {
      email: '',
      phone: '',
      website: '',
      socialMediaValues: null,
    }
  });

  const handleCreateBusinessContact = async (data: BusinessContact) => {
    const payload = {
      businessId: businessId,
      email: data.email,
      ...data,
    };
    const action = await dispatch(addBusinessContact(payload));

    if (addBusinessContact.fulfilled.match(action)) {
      handleNext();
    } else if (addBusinessContact.rejected.match(action)) {
      onShowErrorAlert("", String(action.payload));
    }
  };

  const getSavedBusinessContactDetails = async () => {
    const stepNumber = 2
    const action = await dispatch(getParticularStep({businessId, stepNumber}))
    
      if(getParticularStep.fulfilled.match(action)) {
        reset({
          email: action?.payload?.email || '',
          phone: action?.payload?.phone || '',
          website: action?.payload?.website || '',
          socialMediaValues: action?.payload?.socialMediaValues || null,
        })
      } else if (getParticularStep.rejected.match(action)) {
        console.error('We could not retrieve previously saved data.');
      }
  }
  
  useEffect(() => {
    getSavedBusinessContactDetails()
  }, [])

  return (
    <div>
      <Loader isLoading={loading} />
      <PageHeaderWrapper>
        <BusinessTitle>Business contact details</BusinessTitle>
        <BusinessText>
          Provide your business's key contact details
        </BusinessText>
      </PageHeaderWrapper>

      <FormSection>
        <FormField
          name="email"
          control={control}
          label="Business e-mail address"
          required={true}
          errors={errors}
          rules={{
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Invalid email address",
            },
          }}
        />
      </FormSection>

      <FormSection>
        <Controller
          name="phone"
          control={control}
          rules={{
            required: "Phone number is required",
            pattern: {
              value: /^\+?[1-9]\d{1,14}$/,
              message: "Invalid phone number",
            },
          }}
          render={({ field }) => (
            <InputPhone
              label="Phone number"
              field={field}
              hasError={!!errors.phone}
              height="48px"
            />
          )}
        />
        {errors.phone && <span className="error">{errors.phone.message}</span>}
      </FormSection>

      <FormSection>
        <Controller
          name="website"
          control={control}
          rules={{
            required: false,
            pattern: {
              value: /^(?:[a-zA-Z]+:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)$/,
              message: "Please enter a valid URL (e.g., www.example.com)",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <SocialMediaPicker
              value={value || undefined}
              onChange={onChange}
              addonBefore={"https://"}
              placeholder="Business website (optional)"
            />
          )}
        />

        {errors.website && (
          <span className="error">{errors.website.message}</span>
        )}
      </FormSection>

      <FormSection>
        <h1 className="business-logo-copy">Social media username/links</h1>
        <p className="business-logo-text">
          If your business operates on social media, share the account you use to connect with customers.
        </p>

        <Controller
          name="socialMediaValues"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <SocialMediaPicker
              addonBefore={['Instagram', 'Linkedin', 'X (formerly known Twitter)', 'Facebook', 'WhatsApp']}
              placeholder="Enter your username or profile link here..."
              value={value || []}
              onChange={onChange}
            />
          )}
        />

        {errors?.socialMediaValues && (
          <span className="error">
            {errors?.socialMediaValues?.message?.toString()}
          </span>
        )}
      </FormSection>

      <OnboardingButtonContainer>
        <Button
          label="Back"
          theme="alternate"
          height="48px"
          fontSize="16px"
          width="150px"
          onClick={handlePrevious}
        />
        <Button
          onClick={handleSubmit(handleCreateBusinessContact)}
          label="Continue"
          width={width < 1150 ? '' : '494px' }
          height="48px"
          fontSize="16px"
          disabled={!isValid || loading}
        />
      </OnboardingButtonContainer>
    </div>
  );
};

export default BusinessContactDetails;
