import React from "react";
import { createRoot } from 'react-dom/client';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { QueryClient, QueryClientProvider } from "react-query";
import { PostHogProvider} from 'posthog-js/react';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import "antd/dist/antd.less";
import "currency-flags/dist/currency-flags.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import UserProvider from "./context/UserContext";
import NetworkErrorAlertContextProvider from "./context/NetworkErrorAlert";
import { persistor, store } from "./app/store";
import SuccessAlertContextProvider from "./context/SuccessAlertContext";
import CopyAlertContextProvider from "./context/CopyAlertContext";
import ErrorBoundary from "./ErrorBoundary";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  beforeBreadcrumb(breadcrumb: any) {
    if (breadcrumb.category === "console") {
      return null;
    }
    return breadcrumb;
  },
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
});


const options = {
  api_host: "https://app.posthog.com",
  autocapture: false,
  capture_pageview: false,
  capture_pageleave: false,
}



const queryClient = new QueryClient();
const container = document.getElementById('root');
const root = createRoot(container);

if (process.env.NODE_ENV === 'production') {
    disableReactDevTools();
  }

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <PostHogProvider
          apiKey={process.env.REACT_APP_POSTHOG_KEY}
          options={options}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <UserProvider>
              <QueryClientProvider client={queryClient}>
                <SuccessAlertContextProvider>
                  <NetworkErrorAlertContextProvider>
                    <CopyAlertContextProvider>
                      <ToastContainer/>
                      <App />
                    </CopyAlertContextProvider>
                  </NetworkErrorAlertContextProvider>
                </SuccessAlertContextProvider>
              </QueryClientProvider>
            </UserProvider>
          </PersistGate>
        </Provider>
      </PostHogProvider>
    </ErrorBoundary>
  </React.StrictMode>
  
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
