import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "../../../../../../components/common/Button";
import {
  getCNYQuotationCategory,
  KlashaCNYQuotation,
  uploadCNYMerchantFile,
} from "../../../../redux/slice";
import { ContentWrapper, InputWrapper } from "../styles";
import CustomSelect from "../../../../../../components/common/CustomSelect";
import Loader from "../../../../../../components/common/Loader";
import InputAndSelect from "../../../../../../components/common/InputAndSelect";
import { RateBox } from "../../../../components/Withdrawal/styles";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { useParams } from "react-router-dom";
import {
  cnyPaymentPurpose,
  cnyTypeOfpaymentMethod,
  paymentMethodConfigurations,
} from "../../../../static";
import { debounce } from "lodash";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import { savedCNYData } from "../../../../redux/formDataSlice";
import { encryptAlgorithm } from "../../../../../../utils/encryptionAlgorithm";
import { merchantBusinessKeys } from "../../../../../Settings/settingsSlice";
import { DocumentUpload } from "../../../../components/DocumentUpload";
import { formatCurrencyAmount } from "../../../../../../utils/currency";

type Props = {
  handleNext?: () => void;
  handlePrevious?: () => void;
};

type WalletProps = {
  currency: string;
};

type UserData = {
  amount: number;
  convertedAmount: number;
  sourceCurrency?: string;
  sourceAmount: number | string;
  convertedRate?: number | string;
  destinationCurrency?: string;
  paymentPurpose?: string;
  quotationId?: string;
  paymentMethod?: string;
  rate: any;
  category: string[];
  totalAmount: number;
  file: File;
  fee: number;
  expiration: string;
  reference: string;
};

const Amount = ({ handleNext }: Props) => {
  const CNYData = useAppSelector((state) => state.formData?.cnyData || {});
  const [userData, setUserData] = useState<UserData>(CNYData);
  const { keys } = useAppSelector((state) => state.settings);
  const { wallets, loading } = useAppSelector((state) => state?.wallets || {});
  const { businessId } = useAppSelector((state) => state.users);
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext,
  );
  const { currencyCode } = useParams();
  const dispatch = useAppDispatch();

  const filteredWallets = wallets as WalletProps[];

  const uniqueCurrencies = Array.from(
    new Set(filteredWallets?.map((wallet) => wallet.currency)),
  );

  const resetUserData = (data: UserData): UserData => {
    return Object.keys(data).reduce((acc, key) => {
      acc[key as keyof UserData as any] = null;
      return acc;
    }, {} as UserData);
  };

  const options = uniqueCurrencies.map((currency) => ({
    label: currency,
    value: currency,
  }));

  const filteredOptions = options?.filter(
    (option) => option.value === currencyCode,
  );

  /*
   Remove USD and allow other currency options when business decides otherwise
   */
  const walletToDebit = options?.filter((option) => option.value === "USD");

  const handleAmountChange = (enteredAmount) => {
    setUserData((prevData) => ({
      ...prevData,
      amount: enteredAmount,
    }));
  };

  const handleDataUpdate = (field: keyof UserData, value: any) => {
    setUserData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    dispatch(savedCNYData(userData));
  }, [userData, dispatch]);

  useEffect(() => {
    if (businessId) {
      dispatch(merchantBusinessKeys(businessId));
    }
  }, [businessId, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const action = await dispatch(getCNYQuotationCategory());
      if (getCNYQuotationCategory.fulfilled.match(action)) {
        handleDataUpdate("category", action.payload);
      }
    };

    fetchData();
  }, [dispatch]);

  const debouncedGetKlashaQuotation = useCallback(
    debounce(async () => {
      if (
        !userData?.amount ||
        !userData?.paymentMethod ||
        !userData?.paymentPurpose ||
        !keys?.data?.encryptionKey
      ) {
        return;
      }

      const config = paymentMethodConfigurations[userData.paymentMethod] || {
        serviceCode: "",
        service: "",
        fundSource: "",
      };

      const data = {
        sourceCurrency: "USD",
        destinationCurrency: "CNY",
        destinationAmount: userData.amount,
        ...config,
      };

      const encryptedData = encryptAlgorithm(data, keys?.data?.encryptionKey);
      const payload = { message: encryptedData };
      const token = keys?.data?.publicKey;

      const action = await dispatch(
        KlashaCNYQuotation({ message: payload, token }),
      );

      if (KlashaCNYQuotation.fulfilled.match(action)) {
        const { fxRate, fee, sourceAmount, expiration, id, reference } =
          action.payload;

        const convertedValue = Number(userData?.amount / fxRate);

        setUserData((prev) => ({
          ...prev,
          rate: fxRate,
          convertedAmount: convertedValue,
          convertedRate: convertedValue,
          totalAmount: Number(sourceAmount + fee),
          fee,
          expiration,
          quotationId: id,
          reference,
          sourceAmount,
        }));
      }
    }, 1000),
    [
      dispatch,
      userData?.amount,
      userData?.paymentMethod,
      userData?.paymentPurpose,
    ],
  );

  const { amount, paymentMethod, paymentPurpose } = userData;

  useEffect(() => {
    if (
      amount > 0 &&
      paymentMethod &&
      paymentPurpose &&
      keys?.data?.encryptionKey
    ) {
      debouncedGetKlashaQuotation();
    }
  }, [amount, paymentMethod, paymentPurpose, keys]);

  useEffect(() => {
    if (!amount) {
      setUserData((prev) => resetUserData(prev));
    }
  }, [amount]);

  const handleNextStep = () => {
    dispatch(savedCNYData(userData));
    handleNext();
  };

  const publicKey = keys?.data?.publicKey;

  const handleFileChange = async (title: string, file: File | null) => {
    if (!file) return;

    const action = await dispatch(
      uploadCNYMerchantFile({
        file,
        purpose: paymentPurpose ?? "invoice",
        attachmentType: "COMMERCIAL_DOCUMENTS",
        xAuthToken: publicKey,
      }),
    );

    if (uploadCNYMerchantFile.rejected.match(action)) {
      onShowErrorAlert("error", action.payload as string);
    } else if (uploadCNYMerchantFile.fulfilled.match(action)) {
      handleDataUpdate("file", file);
    }
  };

  return (
    <ContentWrapper>
      <Loader isLoading={loading} />
      <InputAndSelect
        amountValue={userData?.amount}
        width="100%"
        placeholder="Amount"
        options={filteredOptions}
        selectValue={currencyCode}
        onAmountChange={handleAmountChange}
      />
      <CustomSelect
        $width="100%"
        placeholder="Payment method"
        options={cnyTypeOfpaymentMethod.map((option) => ({
          label: option.name,
          value: option.value,
        }))}
        onChange={(data) => handleDataUpdate("paymentMethod", data)}
        value={userData?.paymentMethod}
      />
      <section>
        <RateBox>
          <span className="fee-box">
            x
            <span>{formatCurrencyAmount("", userData?.rate ?? 0, 2) ?? 0}</span>
          </span>
          <span>Exchange rate</span>
        </RateBox>
      </section>

      <section>
        <RateBox>
          <span className="fee-box">
            +<span>{formatCurrencyAmount("", userData?.fee ?? 0, 2) ?? 0}</span>
          </span>
          <span>Fees</span>
        </RateBox>
      </section>

      <CustomSelect
          $width="100%"
          placeholder="Purpose"
          options={
              (paymentMethod === "bank_account_business"
                      ? cnyPaymentPurpose.filter(option => option.name === "Goods purchased")
                      : paymentMethod === "wechatPay"
                          ? cnyPaymentPurpose.filter(option => option.name === "Family support")
                          : cnyPaymentPurpose
              ).map((option) => ({
                  label: option.name,
                  value: option.value,
              }))
          }

          onChange={(data) => handleDataUpdate("paymentPurpose", data)}
          value={userData?.paymentPurpose}
      />

      <InputAndSelect
        amountValue={userData?.totalAmount?.toFixed(2) ?? ""}
        width="100%"
        placeholder="Total you will be charged"
        options={walletToDebit}
        selectValue={"USD"}
      />

      <InputWrapper>
        <h1>Invoice (optional)</h1>
        <p>
          This is required for CNY-B2B (Chinese Yuan Business-to-Business)
          transactions.
        </p>
        <DocumentUpload
          showExtra
          onFileChange={(file: File | null) =>
            handleFileChange("Invoice", file)
          }
        />
      </InputWrapper>

      <Button
        label="Continue"
        onClick={handleNextStep}
        height="48px"
        disabled={
          loading ||
          !userData?.rate ||
          !userData?.paymentMethod ||
          !userData?.paymentPurpose
        }
      />
    </ContentWrapper>
  );
};

export default Amount;
