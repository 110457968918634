import card from "../..//components/common/SideMenu/icons/cards.svg";
import platform from "./assets/platform.svg";
import currency from "./assets/currency.svg";
import shield from "./assets/Shield.svg";
import fundFee from "./assets/fund-fee.svg";
import creationFee from "./assets/creation-fee.svg";

export const lists: any[] = [
  {
    id: 1,
    title: "Generate new card details instantly",
    subText:
      "Create and fund the card with a few clicks at the best exchange rates.",
    icon: <img src={card} alt="card" />,
  },
  {
    id: 2,
    title: "Creation fee",
    subText: "You will be charged a one-off fee of $3.50 to create the card.",
    icon: <img src={creationFee} alt="creation-fee" />,
  },
  {
    id: 3,
    title: "Unlimited spending limit",
    subText: "Shop online, set up subscriptions without the fear of any limit.",
    icon: <img src={currency} alt="no-limit" />,
  },
  {
    id: 4,
    title: "Card maintenance fee",
    subText: "You will be charged a monthly fee of $1.00 to maintain optimal card services.",
    icon: <img src={shield} alt="maintenace-fee" />,
  },
  {
    id: 5,
    title: "Ideal for over 3,424 platforms",
    subText: "Works perfectly on Zoom, AWS, Grammarly, Mailchimp and more.",
    icon: <img src={platform} alt="ideal-platform" />,
  },
  {
    id: 6,
    title: "Card funding fee",
    subText: "You will be charged $1.50 every time you fund your card.",
    icon: <img src={fundFee} alt="funding-fee" />,
  },
];

export const alertItems: any[] = [
  {
    id: 1,
    title:
      "You will be charged $0.50 if your card declines due to insufficient funds.",
  },
  {
    id: 2,
    title:
      "Your card will be terminated if it declines twice, consecutively, due to insufficient funds. The funds on the card will be credited to your wallet.",
  },
  {
    id: 3,
    title: "Fund your card with the amount needed to prevent this charge.",
  },
  {
    id: 4,
    title: "Any refund may take up to 45 - 90 days before it is received.",
  },
];
