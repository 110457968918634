import dayjs from "dayjs";

export const statusOptions = [
  { value: "0", label: "Successful" },
  { value: "1", label: "Pending" },
  { value: "2", label: "Failed" },
  { value: "3", label: "Cancelled" },
  { value: "4", label: "Reversed" },
  { value: "9", label: "All" },
];

const convertToCSV = (data: any[]) => {
  const headers = Object.keys(data[0]).join(",");
  const rows = data.map((item: any) => Object.values(item).join(","));
  return [headers, ...rows].join("\n");
};

export const exportCSV = (data) => {
  if (!data.length) {
    return;
  } else {
    const csvData = data.map((rate, index: number) => ({
      key: index + 1,
      DestinationCurrency: rate.destinationCurrency,
      SourceCurrency: rate.sourceCurrency,
      Rate: rate.rate ? rate.rate.toFixed(2) : "--",
      Date: dayjs(rate.createdAt).format("DD.MM.YYYY"),
    }));

    const csvContent = "data:text/csv;charset=utf-8," + convertToCSV(csvData);
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Rates.csv");
    document.body.appendChild(link);
    link.click();
  }
};
