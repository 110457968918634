import { useMemo } from "react";

const useDocumentStatus = (uploadedDocuments) => {
    const normalizedApprovalStatus = useMemo(() => {
        return uploadedDocuments
            ? uploadedDocuments.map((document) => ({
                  ...document,
                  documentApprovalStatus: document?.documentApprovalStatus ?? "PENDING",
              }))
            : [];
    }, [uploadedDocuments]);

    const disapprovedDocuments = useMemo(() => {
        return normalizedApprovalStatus.find(
            (document) => document.documentApprovalStatus === "FAILED"
        );
    }, [normalizedApprovalStatus]);

    const approvedDocuments = useMemo(() => {
        return normalizedApprovalStatus.every(
            (document) => document.documentApprovalStatus === "APPROVED"
        );
    }, [normalizedApprovalStatus]);

    const everyPendingDocuments = useMemo(() => {
        return normalizedApprovalStatus.find(
            (document) =>
                document.documentApprovalStatus === "PENDING" || null
        );
    }, [normalizedApprovalStatus]);

    return {
        disapprovedDocuments,
        approvedDocuments,
        everyPendingDocuments,
    };
};

export default useDocumentStatus;
