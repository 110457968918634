import React, {
    useContext,
    useEffect,
    useState,
} from "react";
import {  useForm } from "react-hook-form";

import {
    Wrapper,
    Line,
    Heading,
    UploadWrapper,
    ReuploadCopy,
    DocumentUploadCopy,
    Icon,
    ModalWrapper,
} from "../components/StepFour/style";
import {
    getRequiredDocumentWithoutCountry,
    uploadDocument,
} from "../../../../pages/Compliance/complianceAPI";
import {
    useAppSelector,
} from "../../../../app/hooks";
import Loader from "../../../common/Loader";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import { useNavigate } from "react-router-dom";
import DocumentUpload from "../../../common/DocumentUpload/DocumentUpload";
import { Button } from "../../../common/Button";
import CustomModal from "../../../common/CustomModal";

const Reupload = () => {
    const { onShowAlert: onShowErrorAlert } = useContext(
        NetworkErrorAlertContext,
    );
    const {
        handleSubmit,
    } = useForm();
    const [getDocList, setGetDocList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);


    const [mockDocumentStatuses, setMockDocumentStatuses] =
        useState([]);

    const { businessId } = useAppSelector(
        (state) => state.users,
    );

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data =
                    await getRequiredDocumentWithoutCountry(
                        {
                            businessId,
                        },
                    );
                setGetDocList(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        if (businessId) {
            fetchData();
        }
    }, [businessId]);

    const uploadDocumentURL = async (
        downUrl: string,
        busDocId: string,
    ) => {
        const payload = {
            businessDocument: busDocId,
            url: downUrl,
            approved: false,
            business: businessId,
            documentApprovalStatus: "PENDING",
        };

        try {
            await uploadDocument(payload);
        } catch (err) {
            onShowErrorAlert(err, err.message);
        }
    };

    const successModal = () => {
        setSuccess(true);
    };

    const handleDocumentUpload = async (
        url: string,
        index: number,
        id?: string
    ) => {
        setMockDocumentStatuses((prevStatuses) => {
            const newStatuses = [...prevStatuses];
            newStatuses[index] = "LOADING";
            return newStatuses;
        });
    
        try {
            setTimeout(async () => {
                try {
                    await uploadDocumentURL(url, id);
                    setMockDocumentStatuses((prevStatuses) => {
                        const newStatuses = [...prevStatuses];
                        newStatuses[index] = "UPLOADED";
                        return newStatuses;
                    });
    
                    // Add the uploaded document's id to the state variable
                    setUploadedDocuments((prevUploadedDocuments) => [
                        ...prevUploadedDocuments,
                        id, 
                    ]);
                } catch (err) {
                    console.error("Error uploading mock document:", err);
                    setMockDocumentStatuses((prevStatuses) => {
                        const newStatuses = [...prevStatuses];
                        newStatuses[index] = "FAILED";
                        return newStatuses;
                    });
                }
            }, 10);
        } catch (err) {
            console.error("Error uploading mock document:", err);
            setMockDocumentStatuses((prevStatuses) => {
                const newStatuses = [...prevStatuses];
                newStatuses[index] = "INITIAL";
                return newStatuses;
            });
        }
    };

    const failedDocs = getDocList?.filter((doc) => doc?.documentApprovalStatus === "FAILED");
    const isUploadedDocumentsEqualFailedDocs = uploadedDocuments?.length === failedDocs?.length;
    

    const onSubmit = () => {
        successModal()
    };

    return (
        <>
            <Wrapper>
                <Loader isLoading={loading} />
                <Heading>Update your account details</Heading>
                <Line />
                <UploadWrapper>
                    {!loading && (
                        <ReuploadCopy>
                            Status of all documents
                        </ReuploadCopy>
                    )}

                    {getDocList
                        .filter(
                            (doc) =>
                                doc.documentApprovalStatus !==
                                "PENDING"
                        )
                        .map((doc) => (
                            <DocumentUpload
                                key={doc.id}
                                type={
                                    doc.documentApprovalStatus
                                }
                                documentTitle={
                                    doc.businessDocument
                                        .document
                                }
                                imageId={doc.id}
                            />
                        ))}
                </UploadWrapper>

                <UploadWrapper>
                    {!loading && (
                        <ReuploadCopy>
                            Reupload disapproved documents
                        </ReuploadCopy>
                    )}

                    {getDocList
                        ?.filter(
                            (doc) =>
                                doc.documentApprovalStatus ===
                                "FAILED"
                        )
                        ?.map((doc, index) => (
                            <div key={doc.id}>
                            <DocumentUploadCopy>
                                {doc.businessDocument.document}
                            </DocumentUploadCopy>
                            <DocumentUpload
                                key={doc.id}
                                type={
                                    mockDocumentStatuses[
                                        index
                                    ]
                                }
                                onUpload={(newDoc) =>
                                    handleDocumentUpload(
                                        newDoc?.docUrl,
                                        index,
                                        doc.businessDocument.id
                                    )
                                }
                                imageId={doc.id}
                            />
                            
                        </div>
                        ))}
                </UploadWrapper>

                {!loading && (
                    <Button
                        theme="primary"
                        label="Done"
                        onClick={handleSubmit(onSubmit)}
                        disabled={!isUploadedDocumentsEqualFailedDocs}
                    />
                )}
            </Wrapper>

            <CustomModal
                isModalVisible={success}
                onClose={() => setSuccess(false)}
                width="500px"
            >
                <ModalWrapper>
                    <Icon />
                    <h1>Document submitted successfully</h1>
                    <p>
                        Your account is being reviewed. We
                        will notify you when it is approved.
                    </p>

                    <Button
                        label="Continue"
                        theme="secondary"
                        onClick={() =>
                            navigate("/dashboard")
                        }
                    />
                </ModalWrapper>
            </CustomModal>
        </>
    );
};

export default Reupload;
