import React, { useCallback } from "react";
import CustomStepper from "../../../../../components/common/CustomStepper";
import { CnyStepsData } from "../../../static";
import { Description, Title, Wrapper } from "./styles";
import {useAppDispatch, useAppSelector} from "../../../../../app/hooks";
import { setStep } from "../../../redux/formDataSlice";

const stepsItems = CnyStepsData.map((step, index) => ({
  title: step.title,
  description: step.description,
  stepTitle: step.stepTitle,
  stage: index,
}));

const ChinaPayout = () => {
  const dispatch = useAppDispatch();
  const step = useAppSelector((state) => state.formData.step);

  const handleChangeStep = useCallback(
    (index) => {
      if (index >= 0 && index < CnyStepsData.length) {
        dispatch(setStep(index));
      }
    },
    [dispatch]
  );

  const renderStepContent = useCallback(() => {
    const currentStepData = CnyStepsData[step];
    const StepComponent = currentStepData?.component;

    if (StepComponent) {
      return (
        <StepComponent
          handleNext={() => handleChangeStep(step + 1)}
          handlePrevious={() => handleChangeStep(step - 1)}
        />
      );
    }

    return null;
  }, [step, handleChangeStep]);

  return (
    <Wrapper>
      <CustomStepper stages={stepsItems} step={step} setStep={setStep} />
      <Title>{stepsItems[step]?.stepTitle}</Title>
      <Description>{stepsItems[step]?.description}</Description>
      {renderStepContent()}
    </Wrapper>
  );
};

export default ChinaPayout;
