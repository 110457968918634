import styled, { css } from "styled-components";
import { Checkbox } from "antd";

export const Wrapper = styled.div`
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  max-width: 376px;
`;

export const TitleParagraph = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #191919;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const SubtitleParagraph = styled.p`
  font-family: "Athletics";
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.48px;
  text-align: center;
  color: #1a1a1a;
  margin: 0;
`;

export const FormStyle = styled.form`
  margin-top: 30px;
`;

export const FormFooter = styled.div`
  margin-top: 24px;
  align-items: center;
`;

export const DoubleInputDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;

export const DesktopDoubleDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const PasswordErrorBoxFirstStep = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 12px;
`;

export const FormInputDiv = styled.div`
  margin-bottom: ${(props: { $hasError?: boolean }) =>
    props.$hasError ? "16px" : "20px"};
  width: 100%;
  position: relative;
  text-align: left;

  & > span {
    font-family: "Inter";
    font-weight: 400;
    font-size: 10px;
    line-height: 15.6px;
    margin-top: 4px;
    color: var(--primary-pink);
    text-align: left;
  }
    
  @media(max-width: 768px) {
    margin-bottom: ${(props: { $hasError?: boolean }) =>
      props.$hasError ? "12px" : "16px"};
  }
`;

export const CheckboxWrapper = styled(Checkbox)`
  & span[class="ant-checkbox-inner"] {
    border: 1.66667px solid #d1d1d1;
    background-color: transparent;
    border-color: #d1d1d1 !important;
  }

  & span[class~="ant-checkbox-checked"]:after {
    border: 1.66667px solid #000000;
    border-color: #000000;
  }

  & span[class~="ant-checkbox-checked"] span[class="ant-checkbox-inner"] {
    background-color: transparent;
    border-color: #000000;
  }

  &
    span[class~="ant-checkbox-checked"]
    span[class="ant-checkbox-inner"]::after {
    border-color: var(--primary-pink);
  }
`;
export const CheckboxText = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;
  color: #525252;
  text-align: left;
  margin: 0;

  a {
    font-weight: 500;
    color: var(--primary-pink);
    text-decoration: underline;

    &:hover {
      color: #ff4b75;
    }
  }
`;

export const PasswordErrorBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  & p {
    margin-left: 5px;
    margin-bottom: unset;
  }
`;

interface PasswordErrorTitleProps {
  $hasError: boolean;
}

export const PasswordErrorTitle = styled.p<PasswordErrorTitleProps>`
  display: none;
  color: ${(props: { $hasError: boolean }) =>
    props.$hasError ? "#F9123B" : "#0EAB44"};
  @media (min-width: 768px) {
    display: block;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
  }
`;

interface PasswordValidationBoxProps {
  $initialState: boolean;
}

export const PasswordValidationBox = styled.div<PasswordValidationBoxProps>`
  ${(props: { $initialState: boolean }) => {
    if (props.$initialState) {
      return css`
        & p {
          color: #cccccc;
        }
        & ${PasswordErrorBoxWrapper}, & ${PasswordErrorTitle} {
          display: none;
        }
        & ${PasswordErrorBoxBody} {
          border: unset;
        }
      `;
    }
  }}
  ul {
    padding: 0 16px;
    li {
      font-family: "Inter";
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const PasswordValidationItem = styled.li`
  ${(props: { hasError: boolean }) => {
    if (props.hasError) {
      return css`
        color: #767676;
      `;
    } else {
      return css`
        color: #ffffff;
      `;
    }
  }}
`;

export const PasswordErrorBox = styled.div`
  ${(props: { $initialState: boolean }) => {
    if (props.$initialState) {
      return css`
        & p {
          color: #cccccc;
        }
        & ${PasswordErrorBoxWrapper}, & ${PasswordErrorTitle} {
          display: none;
        }
        & ${PasswordErrorBoxBody} {
          border: unset;
        }
      `;
    }
  }}
`;

export const PasswordErrorBoxBody = styled.div`
  border-top: 3px solid #dcdcdc;
  padding-top: 12px;
`;

export const PasswordErrorText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: ${(props: { $hasError: boolean }) =>
    props.$hasError ? "#F9123B" : "#0EAB44"};
`;

export const LoginDiv = styled.div`
  font-family: "Inter";
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #525252;

  a {
    color: #000000;
    text-decoration: underline;

    &:hover {
      color: var(--primary-pink);
    }
  }
`;

export const FormsFlex = styled.div`
  display: flex;
  gap: 24px;

  @media(max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
`;
