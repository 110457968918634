import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../app/api";
import {
  BusinessContact,
  DirectorTypes,
  SettlementTypes,
  Types,
  RequiredDocsParams,
  AdditionalInfoTypes,
  BusinessDeclarationTypes,
  GetParticularStepsParams,
} from "../Types";

type GetLastOnboardingStepsArgs = {
  businessId: string;
};

export const addBusinessInformation = createAsyncThunk(
  "addBusinessInformation",
  async (data: Types, { rejectWithValue }) => {
    try {
      const response = await api.post(`/merchant/business/onboard/info`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred",
      );
    }
  },
);

export const getBusinessType = createAsyncThunk(
  "onboarding/business-type",
  async (_, thunkAPI) => {
    try {
      const response = await api.get("/merchant/businesstype/active");
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "An error occurred",
      );
    }
  },
);

export const getBusinessCategory = createAsyncThunk(
  "onboarding/business-category",
  async (_, thunkAPI) => {
    try {
      const response = await api.get("/merchant/businesscategory");
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "An error occurred",
      );
    }
  },
);

export const getLastOnboardingSteps = createAsyncThunk(
  "onboarding/last-step",
  async ({ businessId }: GetLastOnboardingStepsArgs, thunkAPI) => {
    try {
      const response = await api.get(
        `/merchant/business/onboard/${businessId}/nextStep`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "An error occurred",
      );
    }
  },
);

export const addBusinessContact = createAsyncThunk(
  "addBusinessContact",
  async (data: BusinessContact, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/merchant/business/onboard/contact`,
        data,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred",
      );
    }
  },
);

export const addBusinessDirector = createAsyncThunk(
  "business/addDirector",
  async (
    payload: { businessId: string; directors: DirectorTypes[] },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post(
        "/merchant/business/onboard/director",
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addSettlementDetails = createAsyncThunk(
  "business/settlementDetails",
  async (data: SettlementTypes, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/merchant/business/onboard/account`,
        data,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred",
      );
    }
  },
);

export const getRequiredBusinessDocuments = createAsyncThunk(
  "business/get/required/documents",
  async ({ businessTypeId, country }: RequiredDocsParams, thunkAPI) => {
    try {
      const response = await api.get(
        `/merchant/requireddocument/${businessTypeId}/${country}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "An error occurred",
      );
    }
  },
);

export const addRequiredBusinessDocuments = createAsyncThunk(
  "merchant/business/onboard/businessDocuments",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/merchant/busdocupload/v2", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data.message);
    }
  },
);

export const getListOfDirectors = createAsyncThunk(
  "business/get/directors/list",
  async (businessId: number, thunkAPI) => {
    try {
      const response = await api.get(
        `/merchant/business/onboard/${businessId}/directors/docs`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "An error occured",
      );
    }
  },
);

export const getParticularStep = createAsyncThunk(
  "merchant/get/step",
  async ({ businessId, stepNumber }: GetParticularStepsParams, thunkAPI) => {
    try {
      const response = await api.get(
        `/merchant/business/onboard/${businessId}/steps/${stepNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "An error occured",
      );
    }
  },
);

export const deleteDirector = createAsyncThunk(
  "business/delete/director",
  async ({ directorId, businessId }: any, thunkAPI) => {
    try {
      const response = await api.delete(
        `/merchant/business/onboard/directors/${directorId}/${businessId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "An error occured",
      );
    }
  },
);

export const addDirectorDocuments = createAsyncThunk(
  "business/addDirector/documents",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/merchant/business/onboard/director",
        data,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data.message);
    }
  },
);

export const addAdditionalInformation = createAsyncThunk(
  "/merchant/business/onboard/otherinfo",
  async (data: AdditionalInfoTypes, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/merchant/business/onboard/otherinfo",
        data,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data.message);
    }
  },
);

export const addBusinessDeclaration = createAsyncThunk(
  "business/declaration",
  async (data: BusinessDeclarationTypes, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/merchant/business/onboard/declare",
        data,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data.message);
    }
  },
);
