import { createSlice } from "@reduxjs/toolkit";

interface CardState {
  card: any[];
  wallets: any[];
  cardTransactions: any[];
  merchantBusiness: any[];
  cardBalance: number;
  cardStage: string;
  step: number;
  fundsValue: any;
  amountValue: string;
  withdrawalFundsValue: any;
  withdrawalFundsAmount: string;
  verifyPayment: boolean;
  rate: number;
  withdrawRate: number;
  cardholderId: string;
}

const initialState: CardState = {
  card: [],
  wallets: [],
  cardTransactions: [],
  merchantBusiness: [],
  cardBalance: 0,
  cardStage: "empty",
  step: 1,
  fundsValue: null,
  amountValue: "",
  withdrawalFundsValue: null,
  withdrawalFundsAmount: "",
  verifyPayment: false,
  rate: 0,
  withdrawRate: 0,
  cardholderId: "",
};

export const cardSlice = createSlice({
  name: "cards",
  initialState,

  reducers: {
    setCard: (state, action) => {
      state.card = action.payload;
    },
    setCardTransactions: (state, action) => {
      state.cardTransactions = action.payload;
    },
    setMerchantBusiness: (state, action) => {
      state.merchantBusiness = action.payload;
    },
    setCardBalance: (state, action) => {
      state.cardBalance = action.payload;
    },
    updateCardStage: (state, action) => {
      state.cardStage = action.payload;
    },
    updateCardStep: (state, action) => {
      state.step = action.payload;
    },
    setWallets: (state, action) => {
      state.wallets = action.payload;
    },
    setFundsValue: (state, action) => {
      state.fundsValue = action.payload;
    },
    setAmountValue: (state, action) => {
      state.amountValue = action.payload;
    },
    setWithrawalFundsValue: (state, action) => {
      state.withdrawalFundsValue = action.payload;
    },
    setWithdrawalFundsAmount: (state, action) => {
      state.withdrawalFundsAmount = action.payload;
    },
    setVerifyPayment: (state, action) => {
      state.verifyPayment = action.payload;
    },
    setRate: (state, action) => {
      state.rate = action.payload;
    },
    setWithdrawRate: (state, action) => {
      state.withdrawRate = action.payload;
    },
    setcardholderId: (state, action) => {
      state.cardholderId = action.payload;
    },
  },
});

export const {
  setCard,
  setCardTransactions,
  setCardBalance,
  setWallets,
  setMerchantBusiness,
  updateCardStage,
  updateCardStep,
  setFundsValue,
  setAmountValue,
  setWithdrawalFundsAmount,
  setWithrawalFundsValue,
  setVerifyPayment,
  setRate,
  setWithdrawRate,
  setcardholderId,
} = cardSlice.actions;

export default cardSlice.reducer;
