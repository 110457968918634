import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper } from "../../steps/style";
import Funds from "./Funds";
import Review from "./Review";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  setWithdrawalFundsAmount,
  setWithrawalFundsValue,
} from "../../../CardSlice";
import { withdrawFund } from "../../../CardsApi";
import { animate, exit, generateRandomNumber, initial } from "../../../utils";
import { SuccessAlertContext } from "../../../../../context/SuccessAlertContext";
import Error from "../../steps/ErrorMessage";
import Loader from "../../../../../components/common/Loader";
import { usePostHog } from 'posthog-js/react'
import { AnimatePresence } from "framer-motion";
import CustomStepper from "../../../../../components/common/CustomStepper";

const stages = [
  {
    stage: 1,
    title: "Amount",
  },
  {
    stage: 2,
    title: "Review",
  }
]

const WithdrawFunds = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const posthog = usePostHog();
  const [ref, setRef] = useState('');
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [otp, setOtp] = useState(null)
  const {
    wallets,
    card,
    withdrawalFundsValue,
    withdrawalFundsAmount,
    withdrawRate,
  } = useAppSelector((state) => state.cards);

  const { onShowAlert } = useContext(SuccessAlertContext);

  const selectedWallet = wallets
    ? wallets.find(
        (wallet: { currency: string }) =>
          wallet.currency === withdrawalFundsValue?.label,
      )
    : {};

  const amount = withdrawalFundsAmount ? parseFloat(withdrawalFundsAmount) : 0;
  const convertedAmount = amount * withdrawRate;

  const usdCard = card ? card.find((c) => c.isActive === true) : {};

  useEffect(() => {
    const withdrawFundRef = generateRandomNumber();
    setRef(withdrawFundRef)
  }, []);

  const postHog = (type: string, msg?: string) =>{
    if(type === "success"){
      posthog?.capture("withdraw_black_card_web", { Status: "Successful"})
    }
    if(type === "failed"){
      posthog?.capture("withdraw_black_card_web", { Status: "Failed", Failure_Reason: msg })
    }
  }

  const handleWithdrawFund = async () => {
    const payload = {
      convertedAmount: convertedAmount,
      amount: amount,
      walletId: selectedWallet ? selectedWallet.id : "",
      transRef: `klasha-business-withdraw-funds-${ref}`,
      cardId: usdCard ? usdCard.cardId : "",
      eTag: "4.0.0",
    };

    try {
      setWithdrawLoading(true);
      await withdrawFund(payload);

      postHog("success")
      dispatch(setWithrawalFundsValue(null));
      dispatch(setWithdrawalFundsAmount(""));

      setWithdrawLoading(false);
      navigate("/cards");
      onShowAlert("Withdrawal successful");
    } catch (error) {
      const errorObj = error?.response?.data;

      if (typeof errorObj.error === "string") {
        setErrorMessage(errorObj.error);
      } else if (typeof errorObj.message === "string") {
        setErrorMessage(errorObj.message);
      } else {
        setErrorMessage("Withdrawal failed");
      }

      setWithdrawLoading(false);
      setShowError(true);
      postHog("failed", errorMessage)
    }
    setWithdrawLoading(false);
  };

  return (
  <React.Fragment>
    <Wrapper
      initial={initial}
      animate={animate}
      transition={{ type: "spring", stiffness: 70, when: "beforeChildren" }}
      exit={exit}
    >
    <Loader isLoading={withdrawLoading} />

    <CustomStepper 
      stages={stages}
      step={step}
      setStep={setStep}
      onClose={() => navigate('/cards')}
    />

    {step === 1 && <Funds setStep={setStep} />}

    {step === 2 && <Review setStep={setStep} makeRequest={handleWithdrawFund} code={otp} setCode={setOtp}/>}



    {showError && (
      <Error setShowCardError={setShowError} errorMessage={errorMessage} />
    )}
    </Wrapper>
  </React.Fragment>
  );
};

export default WithdrawFunds;
