import React, { useState, useContext } from "react";
import {
  ReadyWrapper,
  CardImage,
  ShadowImage,
  ImageBox,
  Logo,
  Visa,
  Lines,
  ReadyText,
} from "../style";
import logo from "../../../assets/icons/logo-white.svg";
import visa from "../../../assets/icons/visa.svg";
import lines from "../assets/lines.png";
import { Button } from "../../../components/common/Button";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setAmountValue,
  setCard,
  setCardBalance,
  setCardTransactions,
  setFundsValue,
  updateCardStage,
} from "../CardSlice";
import { initial, transition, exit, animate } from "../utils";
import Loader from "../../../components/common/Loader";
import { getCard, getCardBalance, getCardTransactions } from "../CardsApi";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";

const CardReady = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { userObj } = useAppSelector((state) => state.users);

  const { onShowAlert } = useContext(NetworkErrorAlertContext);

  const handleShowCard = async () => {
    try {
      setLoading(true);
      const data = await getCard(userObj.id);
      const activeCard = data ? data.find((c) => c.isActive === true) : null;

      if (data.length && activeCard) {
        const id = activeCard ? activeCard.cardId : "";

        const cardTransactions = await getCardTransactions(
          id,
          0,
          10,
        );
        const cardBalance = await getCardBalance(id);

        dispatch(setCard(data));
        dispatch(setCardTransactions(cardTransactions));
        dispatch(setCardBalance(cardBalance.data.balance));
      }

      setLoading(false);
      dispatch(setFundsValue(null));
      dispatch(setAmountValue(""));
      dispatch(updateCardStage("empty"));
    } catch (error) {
      const errorObj = error?.response?.data;
      let errorMessage = "";

      if (errorObj && typeof errorObj.error === "string") {
        errorMessage = errorObj.error;
      } else if (errorObj && typeof errorObj.message === "string") {
        errorMessage = errorObj.message;
      } else {
        errorMessage = "Failed, try again";
      }

      setLoading(false);
      onShowAlert(errorObj, errorMessage);
    }
  };

  return (
    <React.Fragment>
      <ReadyWrapper
        initial={initial}
        animate={animate}
        transition={transition}
        exit={exit}
      >
        <Loader isLoading={loading} />

        <CardImage>
          <ImageBox>
            <Logo>
              <img src={logo} alt="logo"/>
            </Logo>
            <Visa src={visa} alt="visa-card" />
            <Lines src={lines} alt="lines" />
          </ImageBox>
          <ShadowImage />
        </CardImage>

        <ReadyText>
          <h1>Your USD virtual card is ready</h1>
          <p>
            You can start making payment online with the real exchange rate and set up your online subscriptions.
          </p>

          <Button
            label="Show me my card"
            fontSize="14px"
            height="48px"
            width="168px"
            onClick={handleShowCard}
          />
        </ReadyText>
      </ReadyWrapper>
    </React.Fragment>
  );
};

export default CardReady;
