import React, { useState } from "react";
import { PhoneInputWrapper, Wrapper } from "./style";
import { CountryCode } from "libphonenumber-js/types";

interface InputProps {
    label: string;
    placeholder?: string;
    field?: any;
    isSecondaryBackground?: boolean;
    hasError?: boolean;
    height?: string;
    defaultCountry?:string;
}

export const InputPhone = ({
    label = "Phone number",
    isSecondaryBackground = false,
    field,
    hasError = false,
    height,
    defaultCountry = "NG"
}: InputProps) => {
    const [hasFocus, setHasFocus] = useState(false);
    const [, setSelectedCountry] = useState<CountryCode>("NG");



    return (
        <Wrapper
            $isSecondaryBackground={isSecondaryBackground}
            $hasError={hasError}
            onFocus={() => {
                setHasFocus(true);
            }}
            onBlur={() => {
                setHasFocus(false);
            }}
            $height={height}
        >
            {hasFocus && (
                <p
                    className={`input-label ${hasFocus ? "top" : ""} ${field?.value?.length ? "has-value" : ""
                        }`}
                >
                    {label}
                </p>
            )}

            <PhoneInputWrapper
                placeholder={!hasFocus && label}
                {...field}
                countrySelectProps={{
                    unicodeFlags: false,
                }}
                onCountryChange={setSelectedCountry}
                defaultCountry={defaultCountry}
            />

        </Wrapper>
    );
};
