/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
    Content,
    Options,
    Wrapper,
    Details,
    DetailsContainer,
    Question,
    YesNoDiv,
    Section,
    InputBox,
} from "./style";
import { modifyComplianceData, getRequiredDocumentsByType, uploadRequiredDocument } from "../../../../../pages/Compliance/complianceSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Declaration from "../Declaration";
import { StepThreeFormInputs } from "../../../types";
import Loader from "../../../../common/Loader";
import { gtagEvent } from "../../../../../utils/gtag";
import { Progress, Radio } from "antd";
import ComplianceRadio from "../../../ComplianceRadio";
import CustomModal from "../../../../common/CustomModal";
import CustomSelect from "../../../../common/CustomSelect";
import { transNumOptions } from "../StepFive/utils";
import { Input } from "../../../../common/Input";
import { Button } from "../../../../common/Button";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import firebaseApp from "../../../../../app/firebase";
import { ProgressWrapper, UploadButton, UploadHeader, InputContainer, UploadBusinessContainer, BusinessUploadWrapper, Agreement } from "../StepTwo/style";
import ErrorMessage from "../../../../ErrorMessage";
import CheckBox from "../../../../common/CheckBox/CheckBox";
import { getMerchantBusiness } from "../../../../../pages/Compliance/complianceAPI";
import { getCountryValue } from "../../../utils";


type ProgressStatus = "idle" | "progress" | "done" | "failed";

const getInitialData = (value) => {
    if (!["yes", "no", null, undefined].includes(value)) return "yes";
    else return "no";
};

const getInitialLaunderingData = (value) => {
    if (["yes", "no", null, undefined].includes(value)) {
      return "no"; 
    } else {
      return "yes"; 
    }
  };
  
  const getInitialTextData = (value) => {
    if (["yes", "no", null, undefined].includes(value)) {
      return ""; 
    } else {
      return value;
    }
  };
  
const hasInitialTextData = (value) => {
    if (["yes", "no", null, undefined].includes(value)) {
        return false;
    } else return true;
};
const StepThree = ({ onSave }: { onSave: () => void }) => {
    const { status: complianceStatus, complianceData, businessTypes } = useAppSelector(
        (state) => state.compliance
    );

    const [companyHasConviction, setCompanyHasConviction] = useState(
        hasInitialTextData(complianceData?.companyConvictions)
    );
    const { business } = useAppSelector((state) => state.settings);
    const { userId, businessId } = useAppSelector((state) => state.users);
    const [starterBusiness, setStarterBusiness] = useState<any>('')
    const [isStarterBusiness, setIsStarterBusiness] = useState<boolean>(false)

    const dispatch = useAppDispatch();
    const [showDeclaration, setShowDeclaration] = useState(false);
    const [uploadingProgress, setUploadingProgress] = useState(0);
    const [uploadStarterBusinessDoc, setUploadStarterBusinessDoc] = useState<any>([]);
    const [uploadingStatus, setUploadingStatus] =
        useState<ProgressStatus>("idle");
    const [invoiceUrl, setInvoiceUrl] = useState("");
    const [laundering, setLaundering] = useState("no")
    const [policy, setPolicy] = useState("no")


    const getStarterBusiness = businessTypes?.find((type) => type.name === 'Business Starter')

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getMerchantBusiness({ businessId: userId });

                if (data && data[0].organizationType === 2) {
                    setStarterBusiness(data[0].organizationType === 2);
                    setIsStarterBusiness(true);
                }
            } catch (err) {
                console.error(err);
            }
        }
        fetchData();
    }, [isStarterBusiness]);

    const [firstElement] = uploadStarterBusinessDoc

    useEffect(() => {
        dispatch(
            getRequiredDocumentsByType({ businessType: getStarterBusiness?.id, country: getCountryValue(business?.country) })
        ).then((data) => {
            setUploadStarterBusinessDoc(data.payload)
        }).catch((err) => {
            console.error(err)
        })

    }, [business]);

    const initialtransCurrAndVal =
        complianceData?.monthlyTransactionValue?.split(" ");
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm<StepThreeFormInputs>({
        defaultValues: {
            companyConvictions: getInitialData(complianceData?.companyConvictions),
            moneyLaunderingAlert: getInitialLaunderingData(
                complianceData?.moneyLaunderingAlert
            ),

            companyConvictionsText: getInitialTextData(
                complianceData?.companyConvictions
            ),
            transValueCurrency: initialtransCurrAndVal
                ? initialtransCurrAndVal[0]
                : "USD",
            transValue: initialtransCurrAndVal ? initialtransCurrAndVal[1] : null,
            fundSource: complianceData?.fundSource,
            transNum: complianceData?.averageTransactionNumber,
            businessId: invoiceUrl,
        },
    });

    useEffect(() => {
        const uploadDoc = {
            businessDocument: firstElement?.businessDocument.id,
            business: business?.id,
            url: invoiceUrl,
            approved: false,
            documentApprovalStatus: "PENDING"
        }
        if (firstElement?.businessDocument.id) {
            dispatch(uploadRequiredDocument(uploadDoc))

        }
    }, [invoiceUrl])



    const uploadImage = (file) => {
        const imageId = file.uid;
        const storage = getStorage(firebaseApp);

        const storageRef = ref(
            storage,
            `compliance/documents/${businessId}/${imageId}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                setUploadingStatus("progress");
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                const progressToTwoDec = Number(progress.toFixed(2));
                setUploadingProgress(progressToTwoDec);
            },
            (error) => {
                setUploadingStatus("idle");
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref)
                    .then((downloadURL) => {
                        setInvoiceUrl(downloadURL);
                        setValue("businessId", downloadURL);

                    })
                    .then(() => {
                        setUploadingStatus("done");

                    });
            }
        );
    };







    const onCompanyConvictionChange = (e) => {
        setCompanyHasConviction(e.target.value === "yes");
    };

    const onSubmit = async (data) => {

        await dispatch(
            modifyComplianceData({
                ...complianceData,
                startupComplianceStatus: "STEP7",
                companyConvictions:
                    data.companyConvictions === "no"
                        ? data.companyConvictions
                        : data.companyConvictionsText,
                moneyLaunderingAlert: laundering,
                pepPolicy: '',
                averageTransactionNumber: data.transNum,
                monthlyTransactionValue: "USD " + data.transValue,
                fundSource: data.fundSource,
                individualConvictions: data.individualConvictions,
                userId: userId
            
            })
        ).then(() => {
            if (starterBusiness) {
                setShowDeclaration(true)

            }
        })
        gtagEvent({
            action: "Compliance_submit_step_3",
            category: "compliance",
            label: "Compliance submit step 3",
        });
    };

    const updateBusiness = () => {
        if (starterBusiness) {
            return null
        } else {
            onSave()
        }
    }

    const onNext: SubmitHandler<StepThreeFormInputs> = (data) => {
        onSubmit(data);
        updateBusiness()
    };

    return (
        <Wrapper onSubmit={handleSubmit(onNext)}>
            <Loader isLoading={complianceStatus === "loading"} />
            <Content>

                <Section>
                    <p>What is your average monthly number of transactions?</p>

                    <Controller
                        name="transNum"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field }) => (
                            <InputBox>
                                <CustomSelect
                                    options={transNumOptions}
                                    $height="40px"
                                    $width="40%"
                                    field={field}
                                    placeholder="Select monthly number"
                                />
                            </InputBox>
                        )}
                    />
                </Section>


                <Section>
                    <p>What is your average monthly value of transactions in USD?</p>
                    <InputBox>
                        <Controller
                            name="transValue"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <Input
                                    labelColor="#FFFFFF"
                                    type="number"
                                    value={field.value}
                                    onChange={field.onChange}
                                    label="Select monthly value"
                                    height="40px"
                                    width="40%"
                                />
                            )}
                        />
                    </InputBox>
                </Section>

                <Section>
                    <p>What is your main source of funds?</p>
                    <InputBox>
                        <Controller
                            name="fundSource"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <InputBox>
                                    <Input
                                        labelColor="#FFFFFF"
                                        type="text"
                                        value={field.value}
                                        onChange={field.onChange}
                                        label="What is your main source of funds?"
                                        height="40px"
                                        width="40%"
                                    />
                                </InputBox>
                            )}
                        />
                    </InputBox>
                </Section>

                {!isStarterBusiness && (
                    <Question>
                        <p>
                            Has your company (or any of its affiliates, shareholders, directors
                            or key employees) ever been the subject of any convictions or
                            prosecutions, or is the subject of any pending investigations, in
                            relation to bribery, corruption or money laundering? If yes, please
                            provide details.
                        </p>
                        <Options>
                            <YesNoDiv>
                                <Controller
                                    name="companyConvictions"
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <Radio.Group
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                onCompanyConvictionChange(e);
                                            }}
                                        >
                                            <ComplianceRadio label="Yes" value="yes" />
                                            <ComplianceRadio label="No" value="no" />
                                        </Radio.Group>
                                    )}
                                />
                            </YesNoDiv>
                            {companyHasConviction && (
                                <Controller
                                    name="companyConvictionsText"
                                    control={control}
                                    rules={{
                                        required: companyHasConviction,
                                    }}
                                    render={({ field }) => (
                                        <DetailsContainer $show={companyHasConviction}>
                                            <Details {...field} placeholder="Details" rows={4} />
                                        </DetailsContainer>
                                    )}
                                />
                            )}
                        </Options>
                    </Question>
                )}

                {isStarterBusiness && (
                    <Question>
                        <p>
                            Have you ever been the subject of any convictions or prosecutions, or is the subject of any pending investigations, in relation to bribery or corruption or money laundering?
                        </p>
                        <Options>
                            <YesNoDiv>
                                <Controller
                                    name="individualConvictions"
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <Radio.Group
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e)
                                            }}
                                        >
                                            <ComplianceRadio label="Yes" value="yes" />
                                            <ComplianceRadio label="No" value="no" />
                                        </Radio.Group>
                                    )}
                                />
                            </YesNoDiv>
                        </Options>
                        {errors.individualConvictions?.type === "required" && (
                            <ErrorMessage message="Field is required" />
                        )}
                    </Question>
                )}

                {!isStarterBusiness && (
                    <Question>
                        <p>
                            Does your company agree to alert Klasha immediately should there be
                            any suspicion of money laundering in relation to its business
                            transactions?
                        </p>
                        <Options>
                            <YesNoDiv>
                                <Controller
                                    name="moneyLaunderingAlert"
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <Radio.Group
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                        >
                                            <ComplianceRadio label="Yes" value="yes" />
                                            <ComplianceRadio label="No" value="no" />
                                        </Radio.Group>
                                    )}
                                />
                            </YesNoDiv>
                        </Options>
                    </Question>
                )}

                {isStarterBusiness && (
                    <Section>
                        <Controller
                            name="businessId"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <>
                                    <InputContainer>
                                        <UploadBusinessContainer>
                                            <UploadHeader>
                                                <p>Proof of  address</p>
                                                <p className="doc-title">Document should not be older than three months and should match the business address provided. Documents that can be used as valid Proof of business address are:</p>
                                                <ul>
                                                    <li>Bank statement (Numbers can be redacted)</li>
                                                    <li>Utility bills such as electricity, waste management, gas, water and cable tv bills.</li>
                                                    <li>Tax assessment</li>
                                                    <li>Letter from a public authority (this includes court notices and affidavits)</li>
                                                </ul>
                                            </UploadHeader>

                                            <BusinessUploadWrapper
                                                name="id"
                                                accept=".doc,.docx,image/*,.pdf"
                                                customRequest={({ file }) => uploadImage(file)}
                                            >
                                                <UploadButton>
                                                    {uploadingStatus === "idle" && (
                                                        <p>
                                                            <span>Click to upload</span> or drag and drop
                                                        </p>
                                                    )}
                                                    {uploadingStatus === "progress" && (
                                                        <ProgressWrapper>
                                                            <Progress
                                                                percent={uploadingProgress}
                                                                showInfo={false}
                                                            />
                                                            <p>{uploadingProgress}%</p>
                                                        </ProgressWrapper>
                                                    )}
                                                    {uploadingStatus === "done" && <p>Done</p>}
                                                </UploadButton>
                                            </BusinessUploadWrapper>
                                        </UploadBusinessContainer>


                                    </InputContainer>
                                    {errors.businessId?.type === "required" && (
                                        <ErrorMessage message="Field is required" />
                                    )}
                                </>
                            )}
                        />
                    </Section>
                )}

                <Agreement>
                    <CheckBox
                        onChange={() => {
                            setLaundering(prevLaundering => prevLaundering === 'yes' ? 'no' : 'yes');
                        }}
                    />
                    <p>We agree to alert Klasha immediately should there be any suspicion of Money Laundering in relation to your business transactions</p>
                </Agreement>
                <Agreement>
                    <CheckBox
                        onChange={() => {
                            setPolicy(prevPolicy => prevPolicy === 'yes' ? 'no' : 'yes');
                        }}
                    />          <p>The company confirms that none of its directors/shareholders fall within the definition of 'politically exposed persons (PEPs)', being natural persons who are or have been entrusted with prominent public functions, including their immediate family members or persons known to be close associates of such persons.</p>
                </Agreement>



            </Content>
            <div>
                <Button
                    label={starterBusiness ? 'Done' : 'Continue'}
                    width="100%"
                    theme="primary"
                    type="button"
                    height="40px"
                    onClick={handleSubmit(onNext)}

                    disabled={laundering === "no" || policy === 'no'}
                />
            </div>

            <CustomModal
                isModalVisible={showDeclaration}
                onClose={() => setShowDeclaration(false)}
                width="775px"
            >
                <Declaration />
            </CustomModal>
        </Wrapper>
    );
};

export default StepThree;
