import styled from "styled-components";

interface WrapperProps {
  $disabled: boolean;
  $labelColor: string;
  $height?: string;
  $width?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  border: 1px solid;
  border-color: ${(props: {
    $disabled: boolean;
    $labelColor: string;
    $height?: string;
    $width?: string;
  }) => (props.$disabled ? "#ececec" : "#E9E9E9")};
  border-radius: 8px;
  height: ${(props) => props.$height ?? "46px"};
  width: ${(props) => props.$width ?? ""};

  &:hover {
    border-color: #a3a3a3;
  }

  &:focus-within {
    border: 1px solid #000000;
  }

  &.password_success_style {
    border: 1px solid #a3a3a3;
  }

  &.is-tooltip-field + .tooltip-container {
    display: none;
  }
  &.is-tooltip-field.has-focus + .tooltip-container {
    display: block;
  }
  &.error,
  &.password_error_style {
    border: 1px solid #f9123b;
  }
  &.error p,
  &.password_error_style p {
    color: #f9123b;
  }

  & p {
    background-color: ${(props) => props.$labelColor || "#fff"};
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    @media (max-width: 375px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: max-content
    }
    &.input-label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8D8D8D;
      position: absolute;
      top: 8px;
      left: 14px;
      margin: 0;
      padding: 0;
      &.has-value {
        z-index: -1;
      }
      &.top {
        font-size: 12px;
        line-height: 16px;
        color: #2f343f;
        transform: translate(-6px, -16px);
        padding: 0 2px;
        z-index: 0;
      }
    }
  }

  input {
    font-family: "Inter";
    padding: 12px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    outline: none;
    color: ${(props) => (props.$disabled ? "#757575" : "none")};

    &::placeholder {
      background-color: transparent;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #565c69 !important;
    }

    &::disabled {
      color: #a3a3a3 !important;
      background: red !important;
    }
  }
`;

export const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 2px;
  right: 5px;
  box-sizing: border-box;
  background-color: transparent;
  padding-bottom: 5px;

  svg {
    height: 20px;
  }
`;

interface Props {
  disabled: boolean;
}

export const InputContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border-radius: 8px;
  height: 100%;
  & svg {
    margin-right: 10px;
  }
`;
