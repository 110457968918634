import React from "react";
import { PayoutWrapper, Heading, Paragraph } from "../../styles";
import { CurrencyTypeWrapper } from "../../../Wallets/components/SendFunds/components/StepAmount/style";
import Local from "./Transfers/Local";
import International from "./Transfers/International";
import { motion  } from "framer-motion";
import { nextVariants } from "../../../Wallets/utils";

interface Props {
  handleNext: () => void;
  activeCurrency: string;
  setActiveCurrency: (data: string) => void;
  amount: number;
  setAmount: any;
  beneficiaryAmount: number;
  setBeneficiaryAmount: (data: number) => void;
  internationalCurrency: any;
  setInternationalCurrency: any;
  intRate?: number;
  setIntRate?: (data: number) => void;
  intTCharges: number;
  setIntTcharges?: (data: number) => void;
  intAmount?: number;
  setIntAmount?: (data: number) => void;
  interNationalFee?: number;
  setInternationalFee?: any;
  country: string;
  setCountry: (data: string) => void;
  sendingCountriesData: string[];
  setSendingCountriesData: (data: string[]) => void;
}

const PayOut = ({
  handleNext,
  activeCurrency,
  setActiveCurrency,
  amount,
  setAmount,
  internationalCurrency,
  setInternationalCurrency,
  intRate,
  setIntRate,
  intTCharges,
  setIntTcharges,
  intAmount,
  setIntAmount,
  interNationalFee,
  setInternationalFee,
  country,
  setCountry,
  beneficiaryAmount,
  setBeneficiaryAmount,
  sendingCountriesData,
  setSendingCountriesData,
}: Props) => {
  const currencyTypes: string[] = ["Naira payout", "International payout"];

  const handleCurrencyTypeClick = (currencyType) => {
    setActiveCurrency(currencyType);
  };

  return (
    <PayoutWrapper>
      <Heading>Payout request</Heading>
      <Paragraph>
        Payouts typically take 1-3 working days and the daily payout limit is{" "}
        <br />
        400,000 NGN for starter businesses.
      </Paragraph>
      <CurrencyTypeWrapper activeCurrency="NGN">
        {currencyTypes?.map((currencyType) => (
          <p
            key={currencyType}
            className={activeCurrency === currencyType ? "active" : "inactive"}
            onClick={() => handleCurrencyTypeClick(currencyType)}
          >
            {currencyType}
          </p>
        ))}
      </CurrencyTypeWrapper>

      {activeCurrency === "Naira payout" ? (
        <React.Fragment>
          <motion.div
            variants={nextVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            key="local"
          >
            <Local
              onNext={handleNext}
              setInputtedAmount={setAmount}
              inputtedAmount={amount}
              beneFiciaryAmount={beneficiaryAmount}
              setBeneficiaryAmount={setBeneficiaryAmount}
            />
          </motion.div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <motion.div
            variants={nextVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            key="international"
          >
            <International
              onNext={handleNext}
              selectedSendingCurrency={internationalCurrency}
              setSelectedSendingCurrency={setInternationalCurrency}
              rate={intRate}
              setRate={setIntRate}
              setTotalCharges={setIntTcharges}
              totalCharges={intTCharges}
              intAmount={intAmount}
              setIntAmount={setIntAmount}
              internationalFee={interNationalFee}
              setInternationalFee={setInternationalFee}
              selectedCountry={country}
              setSelectedCountry={setCountry}
              sendingCountriesData={sendingCountriesData}
              setSendingCountriesData={setSendingCountriesData}
            />
          </motion.div>
        </React.Fragment>
      )}
    </PayoutWrapper>
  );
};

export default PayOut;
