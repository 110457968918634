import React, { useEffect, useRef, useState, Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import gradient from "chartjs-plugin-gradient";
import {
  BottomDiv,
  ChartCardBody,
  OverviewBody,
  OverviewFooter,
  OverviewHead,
  OverviewLabel,
  OverviewValue,
  SalesCardDiv,
  SalesChartContainer,
  SalesChartControls,
  SalesChartControlsLeft,
  SalesChartControlsButton,
  SalesChartDiv,
  SalesChartButtonsContainer,
  SalesChartButtons,
  TopDiv,
  WireCardDiv,
  Wrapper,
  SalesTitle,
  DatePickerRange,
  LineDiv,
  SalesButton,
  DateRange,
  DatePickerWrapper,
} from "./style";
import "../common/CustomDateRangePicker/style.css";
import { filteredDaysSales } from "../../pages/Dashboard/dashboardSlice";
import { ReactComponent as DownArrow } from "./assets/arrow-down.svg";
import { ReactComponent as ChevronDown } from "./assets/chevron-down.svg";
import { ReactComponent as CalendarIcon } from "../common/CustomDateRangePicker/assets/calendar.svg";
import { ReactComponent as PrevIcon } from "../common/CustomDateRangePicker/assets/arrow left.svg";
import { ReactComponent as NextIcon } from "../common/CustomDateRangePicker/assets/arrow-right.svg";
import { ReactComponent as WireLines } from "../../assets/icons/wire-lines.svg";
import {
  getFormattedSalesChartDataForRange,
  lastMonthDateString,
  thisMonthDateString,
  weekDateFromTo,
} from "../../utils/format";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  cardChartData,
  cardChartOptions,
  createGradient,
  mainChartData,
  mainChartOptions,
} from "./utils";
import exportFromJSON from "export-from-json";
import { Button } from "../common/Button";
import dayjs from "dayjs";
import { selectUserRoleAndPermissions } from "../../pages/Settings/Team/redux/slice";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  gradient,
);

interface SalesOverviewProps {
  totalSales: string;
  weekSales: string;
  thisMonthSales: string;
  lastMonthSales: string;
}

const SalesOverview = ({
  totalSales = "",
  weekSales = "",
  thisMonthSales = "",
  lastMonthSales = "",
}: SalesOverviewProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const chartRef = useRef(null);
  const sevenChartRef = useRef(null);
  const thirtyChartRef = useRef(null);
  const lastMonthChartRef = useRef(null);

  const { filteredSales, sevenSales, thirtySales, previousMonthsSales } = useAppSelector(
    (state) => state.dashboard,
  );
  const { businessId } = useAppSelector((state) => state.users);
  const [chartData, setChartData] = useState({ datasets: [] });
  const [sevenDaysChartData, setSevenDaysChartData] = useState({
    datasets: [],
  });
  
        const { onShowAlert} = useContext(
            NetworkErrorAlertContext,
          );
  const {role, permissions } = useAppSelector(
    selectUserRoleAndPermissions,
);
  const [thirtyDaysChartData, setThirtyDaysChartData] = useState({
    datasets: [],
  });
  const [lastMonthChartData, setLastMonthChartData] = useState({
    datasets: [],
  });

  const [date, setDate] = useState("7");
  const [dateRange, setDateRange] = useState(null);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);


  useEffect(() => {
    const chart = sevenChartRef.current;

    if (!chart) {
      return;
    }
    if (sevenSales) {
      const sevenFormattedData = getFormattedSalesChartDataForRange(
        "7",
        sevenSales,
      );
      const sevenData = cloneDeep(cardChartData);
      (sevenData.datasets[0] as any).backgroundColor = createGradient(
        chart.ctx,
        chart.chartArea,
      );

      sevenFormattedData.forEach((sale) => {
        sevenData.labels.unshift(sale.title);

        sevenData.datasets[0].data.unshift(sale.value);
      });
      setSevenDaysChartData(sevenData);
    }
  }, [sevenSales]);

  useEffect(() => {
    const chart = thirtyChartRef.current;

    if (!chart) {
      return;
    }
    if (thirtySales) {
      const thirtyFormattedData = getFormattedSalesChartDataForRange(
        "30",
        thirtySales,
      );
      const thirtyData = cloneDeep(cardChartData);
      (thirtyData.datasets[0] as any).backgroundColor = createGradient(
        chart.ctx,
        chart.chartArea,
      );
      thirtyFormattedData.forEach((sale) => {
        thirtyData.labels.unshift(sale.title);
        thirtyData.datasets[0].data.unshift(sale.value);
      });
      setThirtyDaysChartData(thirtyData);
    }
  }, [thirtySales]);

  useEffect(() => {
    const chart = lastMonthChartRef.current;

    if (!chart) {
      return;
    }
    if (previousMonthsSales) {
      const previousFormattedData = getFormattedSalesChartDataForRange(
        "30",
        previousMonthsSales,
      );
      const previousData = cloneDeep(cardChartData);
      (previousData.datasets[0] as any).backgroundColor = createGradient(
        chart.ctx,
        chart.chartArea,
      );
      previousFormattedData.forEach((sale) => {
        previousData.labels.unshift(sale.title);
        previousData.datasets[0].data.unshift(sale.value);
      });
      setLastMonthChartData(previousData);
    }
  }, [previousMonthsSales]);

  useEffect(() => {
    let formattedData: any[] = [];
    const chart = chartRef.current;

    if (!chart) {
      return;
    } else {
      formattedData = getFormattedSalesChartDataForRange("7", sevenSales);

      const mainCData = cloneDeep(mainChartData);
      (mainCData.datasets[0] as any).backgroundColor = createGradient(
        chart.ctx,
        chart.chartArea,
      );

      formattedData.forEach((sale) => {
        mainCData.labels.unshift(sale.title);
        mainCData.datasets[0].data.unshift(sale.value);
      });
      setChartData(mainCData);
    }
  }, [sevenSales]);

  const getFilteredSalesData = async (
    busId: string,
    day: string,
    val?: any,
  ) => {
    await dispatch(
      filteredDaysSales({ businessId: busId, date: day, values: val }),
    );
  };

  const handleSalesData = async (d: string, salesData: []) => {
    const chart = chartRef.current;
    setDate(d);
    let formattedData;

    if (showButtons) {
      setShowButtons(false);
    }

    if (!chart) {
      return;
    } else {
      try {
        await getFilteredSalesData(businessId, d);
        formattedData = getFormattedSalesChartDataForRange(d, salesData);

        const mainCData = cloneDeep(mainChartData);
        (mainCData.datasets[0] as any).backgroundColor = createGradient(
          chart.ctx,
          chart.chartArea,
        );

        formattedData.forEach((sale) => {
          mainCData.labels.unshift(sale.title);
          mainCData.datasets[0].data.unshift(sale.value);
        });
        setChartData(mainCData);
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const handleCustomSalesData = (d: string) => {
    setDate(d);
    setShowDatePicker(true);

    if (showButtons) {
      setShowButtons(false);
    }
  };

  const handlePicker = (val, str) => {
    setDateRange(val);
    handleRangePicker(str, date, filteredSales);
  };

  const handleRangePicker = async (
    dateString,
    period: string,
    salesData: [],
  ) => {
    let formattedData: any;

    const chart = chartRef.current;
    if (!chart && !dateString) {
      return;
    } else {
      try {
        await getFilteredSalesData(businessId, period, dateString);
        const customDateDiff = dayjs(dateString[1]).diff(dateString[0], "day");

        formattedData = getFormattedSalesChartDataForRange(
          period,
          salesData,
          customDateDiff,
          dateString[1],
        );

        const mainCData = cloneDeep(mainChartData);
        (mainCData.datasets[0] as any).backgroundColor = createGradient(
          chart.ctx,
          chart.chartArea,
        );

        formattedData.forEach((sale) => {
          mainCData.labels.unshift(sale.title);
          mainCData.datasets[0].data.unshift(sale.value);
        });
        setChartData(mainCData);
        return;
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const getDataAvailable = () => {
    let data = [];
    if (date === "7") {
      data = sevenSales?.map((sale) => ({
        Date: sale.date,
        "Number of transactions": sale.count,
        "Total amount": `${sale.currency} ${sale.sum.toFixed(2)}`,
      }));
    } else {
      data = filteredSales.map((sale) => ({
        Date: sale.date,
        "Number of transactions": sale.count,
        "Total amount": `${sale.currency} ${sale.sum.toFixed(2)}`,
      }));
    }
    return data;
  };

  const hasDownloadPermission =
        permissions?.["dashboard"]?.includes("DOWNLOAD_REPORT");

  const downloadReport = async () => {
    if(hasDownloadPermission || role === "OWNER"){
        const data = getDataAvailable();
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName: "Sales report", exportType });
    }else{
        onShowAlert("", "Access is denied");

    
    }
    
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  return (
    <Wrapper>
      <TopDiv>
        <SalesCardDiv $theme="primary">
          <OverviewHead>
            <OverviewLabel>Today&apos;s sales</OverviewLabel>
          </OverviewHead>
          <OverviewFooter>
            <OverviewValue>{totalSales}</OverviewValue>
          </OverviewFooter>
        </SalesCardDiv>
        <SalesCardDiv $theme="secondary">
          <OverviewHead>
            <OverviewLabel>{weekDateFromTo}</OverviewLabel>
          </OverviewHead>
          <OverviewBody>
            <ChartCardBody>
              <Line
                ref={sevenChartRef}
                data={sevenDaysChartData}
                options={cardChartOptions}
              />
            </ChartCardBody>
          </OverviewBody>
          <OverviewFooter>
            <OverviewLabel>This week</OverviewLabel>
            <OverviewValue>{weekSales}</OverviewValue>
          </OverviewFooter>
        </SalesCardDiv>
        <SalesCardDiv $theme="primary">
          <OverviewHead>
            <OverviewLabel>{thisMonthDateString}</OverviewLabel>
          </OverviewHead>
          <OverviewBody>
            <ChartCardBody>
              <Line
                ref={thirtyChartRef}
                data={thirtyDaysChartData}
                options={cardChartOptions}
              />
            </ChartCardBody>
          </OverviewBody>
          <OverviewFooter>
            <OverviewLabel>This month</OverviewLabel>
            <OverviewValue>{thisMonthSales}</OverviewValue>
          </OverviewFooter>
        </SalesCardDiv>
        <SalesCardDiv $theme="primary">
          <OverviewHead>
            <OverviewLabel>{lastMonthDateString}</OverviewLabel>
          </OverviewHead>
          <OverviewBody>
          <ChartCardBody>
              <Line
                ref={lastMonthChartRef}
                data={lastMonthChartData}
                options={cardChartOptions}
              />
            </ChartCardBody>
          </OverviewBody>
          <OverviewFooter>
            <OverviewLabel>Last month</OverviewLabel>
            <OverviewValue>{lastMonthSales}</OverviewValue>
          </OverviewFooter>
        </SalesCardDiv>
      </TopDiv>
      <BottomDiv>
        <SalesChartControls>
          <SalesChartControlsLeft>Sales</SalesChartControlsLeft>
          <SalesChartControlsButton
            variant={!getDataAvailable()}
            onClick={downloadReport}
            disabled={!getDataAvailable()}
          >
            <DownArrow />
            <span>Download report</span>
          </SalesChartControlsButton>
        </SalesChartControls>

        <SalesChartContainer>
          <SalesChartDiv>
            <SalesChartButtonsContainer>
              {showButtons && (
                <DatePickerWrapper onClick={() => setShowButtons(false)} />
              )}
              <SalesTitle onClick={() => setShowButtons(true)}>
                <p>{date}</p>
                <ChevronDown />
              </SalesTitle>

              <SalesChartButtons showButtons={showButtons}>
                {!showDatePicker && (
                  <Fragment>
                    <SalesButton
                      onClick={() => handleSalesData("24", filteredSales)}
                      date={date}
                      value="24"
                      custom={showDatePicker}
                    >
                      24 hours
                    </SalesButton>
                    <SalesButton
                      onClick={() => handleSalesData("7", filteredSales)}
                      date={date}
                      value="7"
                      custom={showDatePicker}
                    >
                      7 days
                    </SalesButton>
                    <SalesButton
                      onClick={() => handleSalesData("30", filteredSales)}
                      date={date}
                      value="30"
                      custom={showDatePicker}
                    >
                      30 days
                    </SalesButton>
                    <SalesButton
                      onClick={() => handleSalesData("3", filteredSales)}
                      date={date}
                      value="3"
                      custom={showDatePicker}
                    >
                      3 months
                    </SalesButton>
                    <SalesButton
                      onClick={() => handleSalesData("12", filteredSales)}
                      date={date}
                      value="12"
                      custom={showDatePicker}
                    >
                      12 months
                    </SalesButton>
                  </Fragment>
                )}
                <SalesButton
                  onClick={() => handleCustomSalesData("custom")}
                  date={date}
                  value="custom"
                  custom={showDatePicker}
                >
                  <p>Custom</p>
                  {showDatePicker && <ChevronDown />}
                </SalesButton>
              </SalesChartButtons>

              {showDatePicker && (
                <DatePickerRange>
                  <DatePickerWrapper onClick={() => setShowDatePicker(false)} />
                  <DateRange
                    value={dateRange}
                    onChange={(dates, dateString) =>
                      handlePicker(dates, dateString)
                    }
                    disabledDate={disabledDate}
                    suffixIcon={<CalendarIcon />}
                    popupClassName="picker-container"
                    prevIcon={<PrevIcon />}
                    nextIcon={<NextIcon />}
                    superPrevIcon={null}
                    superNextIcon={null}
                  />
                </DatePickerRange>
              )}
            </SalesChartButtonsContainer>

            <LineDiv>
              <Line
                ref={chartRef}
                data={chartData}
                options={mainChartOptions}
              />
            </LineDiv>
          </SalesChartDiv>

          <WireCardDiv>
            <WireLines />
            <p>Klasha wire - send money to businesses globally from Africa</p>

            <Button 
              label="Send a wire"
              theme="secondary"
              height="56px"
              width="130px"
              fontSize="14px"
              onClick={()=> navigate("/wire")}
            />
          </WireCardDiv>
        </SalesChartContainer>
      </BottomDiv>
    </Wrapper>
  );
};

export default SalesOverview;
