import React, { useEffect } from "react";
import SalesOverview from "../../components/SalesOverview";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  currentDayTotalSales,
  currentMonthSalesData,
  currentMonthTotalSales,
  currentWeekSalesData,
  currentWeekTotalSales,
  merchantBusinessWallet,
  previousMonthSalesData,
  previousMonthTotalSales,
  sevenDaysSales,
  thirtyDaysSales,
} from "./dashboardSlice";
import EmptyDashboard from "../../components/emptyStates/Dashboard";
import Loader from "../../components/common/Loader";
import { TitleParagraph } from "./style";
import { merchantBankAccounts } from "../Settings/settingsSlice";
import { formatCurrencyAmount } from "../../utils/currency";
import { useOnboardingv2ForDashboardCheck } from "../../app/hooks/useOnboardingv2ForDashboardCheck";


const Dashboard = () => {
  const dispatch = useAppDispatch();
  const isOnboardingV2 = useOnboardingv2ForDashboardCheck()

  const {
    status: stateStatus,
    daysTotal,
    weeksTotal,
    monthsTotal,
    previousMonthsTotal,
  } = useAppSelector((state) => state?.dashboard);
  const {
    status: settingsStatus,
    bankAccounts,
    businessCurrency,
    business,
  } = useAppSelector((state) => state?.settings);

  const { userId, businessId } = useAppSelector((state) => state?.users);

  
  const getMerchantAccounts = async (id: string) => {
    await dispatch(merchantBankAccounts(id));
  };

  const getAllBusinessInfo = async (busId: string) => {
    await dispatch(merchantBusinessWallet(busId));
  };

  useEffect(() => {
    if (userId && businessId) {
      getMerchantAccounts(userId);
      getAllBusinessInfo(businessId);
    }
  }, [userId, businessId]);

  const getSalesData = async (busId: string) => {
    await dispatch(thirtyDaysSales({ businessId: busId }));
    await dispatch(sevenDaysSales({ businessId: busId }));
    await dispatch(currentDayTotalSales({ businessId: busId }));
    await dispatch(currentWeekTotalSales({ businessId: busId }));
    await dispatch(currentMonthTotalSales({ businessId: busId }));
    await dispatch(previousMonthTotalSales({ businessId: busId }));
    await dispatch(currentWeekSalesData({ businessId: busId }));
    await dispatch(currentMonthSalesData({ businessId: busId }));
    await dispatch(previousMonthSalesData({ businessId: busId }));
  };

  useEffect(() => {
    if (businessId && bankAccounts && bankAccounts.length) {
      getSalesData(businessId);
    }
  }, [businessId, bankAccounts]);


  
  if (settingsStatus === "loading" && stateStatus === "loading") {
    return <Loader isLoading />;
  }

  const onboardingV1Check = !business || !Object.keys(business).length ||bankAccounts?.length === 0
  const onboardingV2Check = !business.approved

  return (
   <>
    { (isOnboardingV2 ? onboardingV2Check : onboardingV1Check) ?
      <EmptyDashboard /> 
       :
     <div>
     
        <TitleParagraph>Sales overview</TitleParagraph>
        <SalesOverview
          lastMonthSales={
            previousMonthsTotal[0]
              ? formatCurrencyAmount(
                  businessCurrency,
                  previousMonthsTotal[0]?.sum
                )
              : "0.00"
          }
          thisMonthSales={
            monthsTotal[0]
              ? formatCurrencyAmount(businessCurrency, monthsTotal[0]?.sum)
              : "0.00"
          }
          totalSales={
            daysTotal[0]
              ? formatCurrencyAmount(businessCurrency, daysTotal[0]?.sum)
              : "0.00"
          }
          weekSales={
            weeksTotal[0]
              ? formatCurrencyAmount(businessCurrency, weeksTotal[0]?.sum)
              : "0.00"
          }
        />
      </div>
    } 
  </> 
  );
};

export default Dashboard;
