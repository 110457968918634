import React from "react";
import PendingAccount from "./PendingAccount";
import RequestAccount from "./RequestAccount";

interface Props {
  status: "pending" | "completed" | "failed" | "not-found";
  handleClick: () => void;
  currencyCode: string;
}

const AccountWrapper = ({ status, handleClick, currencyCode }: Props) => {
  let accountComponent;

  switch (status) {
    case "failed":
      accountComponent = (
        <RequestAccount
          handleClick={handleClick}
          accountType={status}
          currencyCode={currencyCode}
        />
      );
      break;
    case "pending":
      accountComponent = <PendingAccount currencyCode={currencyCode} />;
      break;
    default:
      accountComponent = null;
      break;
  }

  return <div>{accountComponent}</div>;
};

export default AccountWrapper;
