import styled from "styled-components";
import CurrencyInput from "react-currency-input-field";

type Prop = {
    $removeCurrencyInput?: boolean;
}
export const StyledCurrencyInput = styled(CurrencyInput)`
    width: 100%;
    display: ${(props: Prop) => (props.$removeCurrencyInput ? 'none' : 'flex')};
    align-items: center;
    border: 1px solid #E9E9E9;
    border-radius: 8px;
    padding: 12px 14px;
    flex: 1 1;
    height: 48px;

    &:hover {
        border-color: #a3a3a3 !important;
    }

    &:focus {
        outline: none;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    position: relative;
    justify-content: flex-end;

    .demo-option-label-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
    }

    .curr-code {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .select {
        position: absolute;
        margin: 1px 8px 25px;
        display: flex;
        border-radius: 0px 8px 40px 0px;
        height: 48px;
    }

    
`;
