import React, { useContext, useEffect, useState } from "react";
import {
  AddButton,
  BtnWrapper,
  BusinessText,
  BusinessTitle,
  DirectorIndex,
  IconWrapper,
  OnboardingButtonContainer,
  PageHeaderWrapper,
} from "../styles";
import FormSection from "../components/FormSection";
import CustomDatePicker from "../../../components/common/CustomDateRangePicker/CustomDatePicker";
import DeleteIcon from "../icons/delete.svg";
import PlusIcon from "../icons/plus.svg";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { DirectorTypes } from "../Types";
import CountrySelect from "../../../components/common/CountrySelect";
import { Button } from "../../../components/common/Button";
import { addBusinessDirector, getParticularStep } from "../redux/actions";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Loader from "../../../components/common/Loader";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import { Input } from "../../../components/common/Input";
import { updateDirectorsList } from "../redux/slice";
import { useWindowSize } from "@reach/window-size";
import { formatDate } from "../../../utils/dateUtils";
import dayjs from 'dayjs';
import 'dayjs/locale/en';
dayjs.locale('en'); 

type Props = {
  handleNext: () => void;
  handlePrevious: () => void;
};

const Director = ({ handleNext, handlePrevious }: Props) => {
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset
  } = useForm<DirectorTypes>({
    defaultValues: {
      directors: [{ id: null, name: "", dateOfBirth: null, nationality: "" }],
    },
    mode: 'onChange'
  });
  const dispatch = useAppDispatch();
  const { width } = useWindowSize()
  const { businessId, userObj } = useAppSelector((state) => state.users);
  const { loading } = useAppSelector((state) => state.onboarding || {});
  const { onShowAlert: onShowErrorAlert } =
    useContext(NetworkErrorAlertContext) || {};
  const { fields, append, remove } = useFieldArray({
    control,
    name: "directors",
  });

  const addDirector = () => {
    append({ name: "", dateOfBirth: "" });
  };

  const handleAddDirector = async (data: DirectorTypes) => {
    const payload = data.directors.map(director => ({
      businessId: businessId,
      name: director.name,
      dob: director.dateOfBirth,
      nationality: director.nationality,
      ...(director.id && {id: director.id})
    }));
    // @ts-ignore
    const action = await dispatch(addBusinessDirector(payload));
    if (addBusinessDirector.fulfilled.match(action)) {
      dispatch(updateDirectorsList(action.payload))
      handleNext();
    } else if (addBusinessDirector.rejected.match(action)) {
      onShowErrorAlert('', String(action.payload));
    }
  };

  const getSavedDirectorsDetails = async () => {
    const stepNumber = 3
    const action = await dispatch(getParticularStep({businessId, stepNumber}))
    
      if(getParticularStep.fulfilled.match(action)) {
        const savedDirectors = action?.payload?.length > 0 
        ? action?.payload?.map((dir) => ({
            id: dir.id,
            name: dir.name, 
            dateOfBirth: dir.dob ? dayjs(dir.dob) : null,
            nationality: dir.nationality 
          }))
        : [{ id: null, name: "", dateOfBirth: null, nationality: null }];

      reset({
        directors: savedDirectors
      });
      } else if (getParticularStep.rejected.match(action)) {
        console.error('We could not retrieve previously saved data.');
      }
  }
  
  useEffect(() => {
    getSavedDirectorsDetails()
  }, [])

  return (
    <div>
      <Loader isLoading={loading} />
      <PageHeaderWrapper>
      <BusinessTitle>Directors&apos; and Shareholders&apos; details</BusinessTitle>
      <BusinessText>
        Directors and Ultimate beneficial owners (anyone that holds a minimum of 20% shares)
      </BusinessText>
      </PageHeaderWrapper>

      {fields.map((director, index) => (
        <div key={director.id}>
          <IconWrapper>
            <DirectorIndex>Director {index + 1}</DirectorIndex>
            <button onClick={() => remove(index)}>
              <img src={DeleteIcon} alt="delete-icon" />
            </button>
          </IconWrapper>

          <FormSection>
            <Controller
              name={`directors[${index}].name` as any}
              control={control}
              rules={{ required: "Name is required" }}
              render={({ field }) => (
                <>
                  <Input
                    height="48px"
                    label="Director's Name"
                    {...field}
                    hasError={!!errors.directors?.[index]?.name}
                  />
                  {errors.directors?.[index]?.name && (
                    <span className="error">
                      {errors.directors[index].name.message}
                    </span>
                  )}
                </>
              )}
            />
          </FormSection>

          <FormSection>
            <Controller
              name={`directors[${index}].dateOfBirth` as any}
              control={control}
              rules={{ required: "Date of Birth is required" }}
              render={({ field }) => (
                <>
                  <CustomDatePicker
                    lightBorder={true}
                    {...field}
                    placeholder="Date of birth"
                  />
                  {errors.directors?.[index]?.dateOfBirth && (
                    <span className="error">
                      {errors.directors[index].dateOfBirth.message}
                    </span>
                  )}
                </>
              )}
            />
          </FormSection>

          <FormSection>
            <Controller
              name={`directors[${index}].nationality` as any}
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <CountrySelect 
                  value={field.value} 
                  onChange={field.onChange} 
                  placeholder="Nationality" 
                  allCountries={true} 
                  lightBorder={true} 
                  height="48px"
                />
              )}
            />
            {errors.directors?.[index]?.nationality && (
              <span className="error">
                {errors.directors[index].nationality.message}
              </span>
            )}
          </FormSection>
        </div>
      ))}

      <AddButton onClick={addDirector}>
        <img src={PlusIcon} alt="plus-icon" />
        Add new
      </AddButton>

      <OnboardingButtonContainer>
        <Button
          label="Back"
          theme="alternate"
          height="48px"
          fontSize="16px"
          width="150px"
          onClick={handlePrevious}
        />
        <Button
          label="Continue"
          width={width < 1150 ? '' : '494px' }
          height="48px"
          fontSize="16px"
          type="button"
          onClick={handleSubmit(handleAddDirector)}
          disabled={!isValid || loading}
        />
      </OnboardingButtonContainer>
    </div>
  );
};

export default Director;
