import api from "../../app/api";
import api2 from "./api";

export async function getRate(
  sourceCurr: string,
  destCurr: string,
  amt: number,
  withdrawState?: boolean,
) {
  const payload = {
    sourceCurrency: sourceCurr,
    amount: amt,
    destinationCurrency: destCurr,
    withdraw: withdrawState,
  };
  const { data } = await api.post(
    `/nucleus/exchange/`,
    JSON.stringify(payload),
  );
  return data;
}

export async function getWallets(busId: number) {
  const { data } = await api.get(`/wallet/business/${busId}/all`);
  return data;
}

export async function createCard(payload: any) {
  const { data } = await api.post(
    "/wallet/black/card/merchant/create/v2",
    JSON.stringify(payload),
  );
  return data;
}

export async function getCard(id: number) {
  const { data } = await api.get(`/nucleus/black/card/merchant/${id}/all`);
  return data;
}

export async function getCardTransactions(
  id: string,
  page: number,
  size: number,
) {
  const { data } = await api.get(
    `/nucleus/black/transactions/paginated/${id}?page=${page}&size=${size}`,
  );
  return data;
}

export async function getCardBalance(id: string) {
  const { data } = await api.get(
    `/wallet/black/card/merchant/fetch/balance/${id}`,
  );
  return data;
}

export async function blockCard(id: string) {
  const { data } = await api2.post(
    "/pay/black/card/merchant/freeze",
    JSON.stringify({ cardId: id }),
  );
  return data;
}

export async function unBlockCard(id: string) {
  const { data } = await api2.post(
    "/pay/black/card/merchant/unfreeze",
    JSON.stringify({ cardId: id }),
  );
  return data;
}

export async function sendFund(payload: any) {
  const { data } = await api.post(
    "/wallet/black/card/merchant/fund/v2",
    JSON.stringify(payload),
  );

  return data;
}

export async function withdrawFund(payload: any) {
  const { data } = await api.post(
    "/wallet/black/card/merchant/withdraw",
    JSON.stringify(payload),
  );
  return data;
}

export async function getBusinessKeys(businessId: number) {
  const { data } = await api.get(`merchant/bus/keys/${businessId}`);
  return data;
}

export const getMerchant = async (userId: string) => {
  const { data } = await api.get(`merchant/user/${userId}`);
  return data;
};

export const resendCardOTP = async (id: string) => {
  const data = await api.post(
    "/wallet/black/card/resend/otp",
    JSON.stringify({ cardholderId: id }),
  );
  return data;
};

export const verifyCardOTP = async (values: string, id: string) => {
  const payload = {
    otp: values,
    cardholderId: id,
  };

  const { data } = await api.post(
    "/wallet/black/card/verify/otp",
    JSON.stringify(payload),
  );
  return data;
};
