import styled, {
    css,
    StyledComponent,
} from "styled-components";
import { ReactComponent as UploadIcon } from "../../../assets/icons/upload-icon.svg";
import { ReactComponent as Loading } from "../../../assets/icons/loading-circle.svg";
import { ReactComponent as Approved } from "../../../assets/icons/check-icon.svg";
import { ReactComponent as Pending } from "../../../assets/icons/pending-icon.svg";
import { ReactComponent as Failed } from "../../../assets/icons/failed-icon.svg";

export const DocumentWrapper = styled.input`
    display: none;
`;

export const Label = styled.label`
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #dbdbdb;
    padding: 10px;
    cursor: pointer;
    justify-content: space-between;
`;

interface ContentProps {
    status?: "INITIAL" | "LOADING" | "APPROVED" | "UPLOADED" | "PENDING" | "FAILED";
    children?: React.ReactNode;
}


type ContentStyledComponent = StyledComponent<
    React.FC<ContentProps>,
    any>

export const Content: ContentStyledComponent = styled(
    ({ status, ...rest }: ContentProps) => (
        <div {...rest} />
    )
)`
    display: flex;
    gap: 20px;

    h3 {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        margin-top: 10px;
        color:#000000;
    }

    p {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        color: #818691;

        ${(props: ContentProps) =>
            (props.status === "INITIAL" ||
                props.status === "LOADING") &&
            css`
                color: #818691;
            `}

        ${(props: ContentProps) =>
            props.status === "APPROVED" &&
            css`
                color: #00c853;
            `}

            ${(props: ContentProps) =>
                props.status === "UPLOADED" &&
                css`
                    color: #00c853;
                `}
    
        ${(props: ContentProps) =>
            props.status === "PENDING" &&
            css`
            color: #B45309;
            
            `}
    
        ${(props: ContentProps) =>
            props.status === "FAILED" &&
            css`
                color: #ff0000;
            `}
    }
`;



export const Icon = styled(({ iconType, ...rest }) => {
    if (iconType === "LOADING") {
        return <Loading {...rest} />;
    } else if (iconType === "APPROVED") {
        return <Approved {...rest} />;
    }else if(iconType === 'UPLOADED'){
        return <Approved {...rest} />;

    } 
    
    else if (iconType === "PENDING") {
        return <Pending {...rest} />;
    } else if (iconType === "UPLOADED") {
        return <Approved {...rest} />;
    } else if (iconType === "FAILED") {
        return <Failed {...rest} />;
    } else {
        return <UploadIcon {...rest} />;
    }
})`
    margin-left: 10px;
    margin-top: 10px;
`;

export const ReuploadIcon = styled(UploadIcon)`
margin-left: 50px;
margin-top: 15px;

`