import React, { useEffect } from 'react';
import { Controller, Control } from 'react-hook-form';
import { CurrencySelector } from '../../../components/common/CurrencySelector';
import { SettlementTypes } from "../Types";
import { getCurrencyAbbreviation, getCountryByAbbreviation, getCurrencyList } from "country-currency-map";

interface CurrencySelectorProps {
    countryValue: string;
    control: Control<SettlementTypes>;
    setValue: any;
}

export const SettlementCurrencySelector: React.FC<CurrencySelectorProps> = ({
    countryValue,
    control,
    setValue
}) => {

    const currencyOptions = getCurrencyList()
    const allCurrencyOptions = currencyOptions.map(currency => ({
        value: currency.abbr,
        label: currency.abbr,
        fullName: currency.name
    }));

    const getCountryCurrency = (countryCode: string): string => {
        try {
            const countryName = getCountryByAbbreviation(countryCode?.toUpperCase())
            const currency = getCurrencyAbbreviation(countryName)
            return currency || '';
        } catch (error) {
            console.error('Error getting currency for country:', error);
            return '';
        }
    };

    useEffect(() => {
        const defaultCurrency = getCountryCurrency(countryValue);
        if (defaultCurrency) {
            setValue('currency', defaultCurrency, {
                shouldValidate: true,
                shouldDirty: true
            });
        }
    }, [countryValue]);

    return (
        <Controller
            name="currency"
            control={control}
            rules={{ required: "Currency is required" }}
            render={({ field, fieldState: { error } }) => (
                <div>
                    <CurrencySelector
                        countries={allCurrencyOptions}
                        selectValue={field.value || ''}
                        onSelectChange={(value) => {
                            field.onChange(value);
                            setValue('currency', value, {
                                shouldValidate: true,
                                shouldDirty: true
                            });
                        }}
                        styleForOnboardingSettlement={true}
                    />
                    {error && <span className="text-red-500 text-sm">{error.message}</span>}
                </div>
            )}
        />
    );
};