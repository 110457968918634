import React, {
  createContext,
  ReactNode,
  useEffect,
  useState,
} from "react";
import * as Sentry from "@sentry/react";

type NetworkErrorAlertContextType = {
  showAlert?: boolean;
  alertMessage?: string;
  onShowAlert?: (error: any, message: string) => void
}

export const NetworkErrorAlertContext = createContext<NetworkErrorAlertContextType>({
  showAlert: false,
  alertMessage: "",
  onShowAlert: () => null,
});

const NetworkErrorAlertContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const onShowAlert = (err: any, message: string): void => {
    setShowAlert(true);
    setAlertMessage(message);
    if (err) {
      Sentry.captureException(err);
    }
  };

  return (
    <NetworkErrorAlertContext.Provider
      value={{
        showAlert,
        alertMessage,
        onShowAlert,
      }}
    >
      {children}
    </NetworkErrorAlertContext.Provider>
  );
};

export default NetworkErrorAlertContextProvider;
