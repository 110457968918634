import React from "react";
import useCountryList from "../../../app/hooks/useCountryList";
import { OptionLabel, FlagImage } from "./styles";
import CustomSelect from "../CustomSelect";
import { Select } from "antd";

type CountryOption = {
  label: string;
  value: string;
};

type CountrySelectProps = {
  value?: any;
  onChange?: (data: CountryOption | null) => void;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  allCountries?: boolean;
  lightBorder?: boolean;
  height?: string
};

const CountrySelect: React.FC<CountrySelectProps> = ({
  value,
  onChange,
  defaultValue,
  disabled,
  placeholder="Select a country",
  allCountries = false,
  lightBorder = false,
  height = ''
}) => {
  const countries = useCountryList(allCountries);

  const handleChange = (selectedOption: CountryOption | null) => {
    onChange(selectedOption);
  };

  const filterByLabel = (input: string, option: any) => {
    return option?.label?.toLowerCase().includes(input?.toLowerCase());
  };

  return (
    <CustomSelect
      value={value}
      defaultValue={defaultValue}
      onChange={handleChange}
      disabled={disabled}
      $width="100%"
      placeholder={placeholder}
      showSearch={true}
      filterOption={filterByLabel}
      lightBorder={lightBorder}
      $height={height}
    >
      {countries.map((country) => (
        <Select.Option 
          key={country.value} 
          value={country.value} 
          label={country.label}
        >
        <OptionLabel key={country.value}>
          <FlagImage
            src={`https://flagcdn.com/w80/${country.value.toLowerCase()}.png`}
            alt={country.label}
          />
          {country.label}
        </OptionLabel>
        </Select.Option>
      ))}
    </CustomSelect>
  );
};

export default CountrySelect;
