import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Funds from "../GeneralComponents/Funds";
import Review from "../GeneralComponents/Review";
import { ReactComponent as BackIcon } from "../../../../assets/icons/back.svg";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { Wrapper, Back, Fee } from "./style";
import { updateCardStage, updateCardStep } from "../../CardSlice";
import {
  initial,
  animate,
  transition,
  exit,
} from "../../utils";
import CreatePayment from "../GeneralComponents/CreatePayment";
import CustomStepper from "../../../../components/common/CustomStepper";


const stages = [
  {
    stage: 1,
    title: "Add funds",
  },
  {
    stage: 2,
    title: "Review",
  }
]

const Steps = () => {
  const dispatch = useAppDispatch();
  const {step, verifyPayment } = useAppSelector((state) => state.cards);

  const handleCloseSteps = () => {
    dispatch(updateCardStage("empty"));
    dispatch(updateCardStep(1));
  };

  const handleFunds = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    dispatch(updateCardStep(2));
  };

  const creationFeeAlert = (
    <Fee>
      <p>
        <PlusIcon />
      </p>
      <p>3.50 USD</p>
      <p>Card creation fee</p>
    </Fee>
  );

  return (
    <React.Fragment>
      <Wrapper
        initial={initial}
        animate={animate}
        transition={{ type: "spring", stiffness: 70, when: "beforeChildren" }}
        exit={exit}
      >
      
      <CustomStepper
        step={step}
        stages={stages}
        onClose={handleCloseSteps} 
      />

      {step === 1 && (
        <Funds
          handleFunds={handleFunds}
          fee={3.50}
          feeAlert={creationFeeAlert}
          IncreaseHeight={true}
          smallText="A one-time cost of 3.50 USD will be charged for card creation."
        />
      )}

      {step === 2 && (
        <Back
          initial={initial}
          animate={animate}
          transition={transition}
          exit={exit}
          data-testid='back-btn'
          onClick={() => dispatch(updateCardStep(1))}
        >
          <BackIcon />
        </Back>
      )}

      {step === 2 && (
        <Review
          feeText="Card creation fee"
          fee={3.50}
          type="create-card"
          onEdit={() => dispatch(updateCardStep(1))}
        />
      )}

      {verifyPayment && <CreatePayment />}
    </Wrapper>
  </React.Fragment>
  );
};

export default Steps;
