import Amount from "./pages/SingleWallet/ChinaPayout/steps/Amount";
import Sender from "./pages/SingleWallet/ChinaPayout/steps/Sender";
import Beneficiary from "./pages/SingleWallet/ChinaPayout/steps/Beneficiary";
import TransactionDetails from "./pages/SingleWallet/ChinaPayout/steps/TransactionDetails";
import Review from "./pages/SingleWallet/ChinaPayout/steps/Review";

export const industries = [
  "Accounting and auditing",
  "Activities of holding company",
  "Activities of trust",
  "Advertising agencies",
  "Affiliate Marketing",
  "Affiliate gambling or betting",
  "Air transport",
  "Amusement parks and theme parks",
  "Animal production",
  "Aquaculture",
  "Artistic creation",
  "Asset management",
  "Banks or building societies",
  "Barristers",
  "Beverage serving activities",
  "Biotechnology",
  "Bookkeeping",
  "Books and other literature",
  "Brokerages",
  "Business and employers membership organisation",
  "Buying, selling or renting own real estate",
  "Cash intensive",
  "CBD",
  "Charity",
  "Child day-care",
  "Cleaning activities",
  "Compulsory social security",
  "Computer programming",
  "Construction of buildings",
  "Construction of roads and railways",
  "Construction of utilities",
  "Data processing",
  "Demolition or site preparation",
  "Direct gambling or betting",
  "Educational support activities",
  "Electrical, plumbing or other construction installation activities",
  "E-money",
  "Employment services",
  "Exchanges",
  "Extraction of oil or natural gas",
  "Financial consulting",
  "Fire service",
  "Fishing",
  "Foreign affairs",
  "General public administration",
  "Growing of crops",
  "Hair & beauty services",
  "Higher education",
  "Hospital activities",
  "Hotels or similar",
  "HR consulting",
  "Hunting",
  "Investigation activities",
  "IT consulting",
  "Landscaping activities",
  "Lead generation",
  "Leasing of intellectual property and similar products",
  "Library or archives",
  "Life insurance",
  "Logging",
  "Maintenance and repair of motor vehicles",
  "Manufacture of beverages",
  "Manufacture of chemicals",
  "Manufacture of electrical equipment",
  "Manufacture of food products",
  "Manufacture of furniture",
  "Manufacture of metal or metal products",
  "Manufacture of refined products relating to petroleum",
  "Manufacture of rubber or plastic",
  "Manufacture of textiles",
  "Manufacture of tobacco products",
  "Manufacture of vehicles or transport equipment",
  "Manufacture of wood and wood products",
  "Market research",
  "Media representation services",
  "Medical and dental practice activities",
  "Miners",
  "Mining",
  "Money Service Business",
  "Motion picture; video or TV",
  "Natural sciences",
  "Niche or alternative dating",
  "Non-life insurance",
  "NutriScience",
  "Office admin or support activities",
  "Operation of fitness or sporting facilities",
  "Operations consulting",
  "Other",
  "Other accounting",
  "Other advertising",
  "Other agriculture",
  "Other amusement or recreation activities",
  "Other arts",
  "Other construction",
  "Other crypto",
  "Other dating",
  "Other education",
  "Other extraction",
  "Other finance",
  "Other fishing",
  "Other forestry",
  "Other gambling or betting",
  "Other human health activities",
  "Other insurance",
  "Other IT",
  "Other land transport",
  "Other legal",
  "Other manufacture",
  "Other marketing",
  "Other media",
  "Other membership organisations",
  "Other mining",
  "Other personal services",
  "Other pharmaceutical",
  "Other professional services",
  "Other real estate",
  "Other renting or leasing",
  "Other research & development",
  "Other sale",
  "Other security",
  "Other sports activities",
  "Other storage",
  "Other transport",
  "Other utility",
  "Payday loans",
  "Payment institute",
  "Pension funding",
  "Performing arts",
  "Pharmaceutical manufacture",
  "Physical well-being services",
  "Political organisations",
  "Postal or courier",
  "Power",
  "Pre-primary education",
  "Primary education",
  "Private Banking",
  "Private security activities",
  "Professional membership organisations",
  "Public defence",
  "Public justicial activities",
  "Public order and safety",
  "Quarrying",
  "R&D",
  "Racehorse owners",
  "Radio broadcasting",
  "Rail transport",
  "Real estate agencies",
  "Regulation of businesses",
  "Regulation of public services",
  "Religious organisations",
  "Remediation or other waste management services",
  "Renting of motor vehicles",
  "Renting of other machinery, equipment and tangible goods",
  "Renting of personal or household goods",
  "Residential care activities",
  "Restaurants and other food-serving establishments",
  "Risk and damage evaluation",
  "Road transport",
  "Sale of food",
  "Sale of fuel",
  "Sale of goods",
  "Sale of IT or electronics",
  "Sale of machinery",
  "Sale of materials",
  "Sale of motor vehicles",
  "Sale or scrapping of metal",
  "Secondary education",
  "Security systems service activties",
  "Services to the gambling or betting industry",
  "Sewerage",
  "Social media related",
  "Social work activities",
  "Software providers",
  "Software publishing",
  "Solicitors",
  "Sound recording and music",
  "Sports + recreation education",
  "Sports clubs",
  "Strategy consulting",
  "Support activities for mining/quarrying",
  "Support activities for oil/gas extraction",
  "Support activities for transport",
  "Support activities to agriculture",
  "Support services to forestry",
  "Telecommunications activities",
  "Trade unions",
  "Trading",
  "Traditional dating",
  "Transport via pipeline",
  "Travel agency or services",
  "Warehousing and storage",
  "Waste collection",
  "Water transport",
  "Water treatment or supply",
  "Wealth Management",
];

export const companyEnterprise = [
  { value: "ltd", label: "LTD" },
  { value: "partnership", label: "Partnership" },
  { value: "plc", label: "PLC" },
];

export const staticCurrencies = ["KES", "ZMW", "ZAR", "XOF"];

export const accountsCurrencyDetails = {
  ZMW: {
    accountNumber: "5615000014466",
    branchCode: "360002",
    bankName: "Ecobank Limited",
    accountType: "360002",
  },
  ZAR: {
    accountNumber: "1227634846",
    bankName: "Nedbank",
    accountType: "Nedszajj",
  },
  KES: {
    accountNumber: "55010160015196",
    bankName: "UBA",
    accountType: "UNAFKENA",
  },

  XOF: {
    accountNumber: "55010160015196",
    bankName: "UBA",
    accountType: "UNAFKENA",
  },
};

export const CnyStepsData = [
  {
    number: 0,
    title: "Amount",
    stepTitle: "How much are you sending?",
    description:
      "Specify the amount to transfer. Payment method affects the total charge.",
    component: Amount,
  },
  {
    number: 1,
    title: "Sender",
    stepTitle: "Are you the ultimate sender?",
    description:
      "Specify the sender's identity your full legal name or official business name",
    component: Sender,
  },
  {
    number: 2,
    title: "Beneficiary",
    stepTitle: "Who are you sending to?",
    description:
      "Specify the sender's identity your full legal name or official business name",
    component: Beneficiary,
  },
  {
    number: 3,
    title: "Payment details",
    stepTitle: "Transaction details",
    description: null,
    component: TransactionDetails,
  },
  {
    number: 4,
    title: "Review",
    stepTitle: "Review the details of your transaction",
    component: Review,
  },
];

export const cnyTypeOfpaymentMethod = [
  { name: "UnionPay", value: "unionpay" },
  { name: "Alipay", value: "alipay" },
  { name: "WeChatPay", value: "wechatpay" },
  { name: "Bank Account (Individual)", value: "bank_account_individual" },
  { name: "Bank Account (Business)", value: "bank_account_business" },
];

export const cnyPaymentPurpose = [
  { name: "Salary", value: "SALARY" },
  { name: "Family support", value: "FAMILY_SUPPORT" },
  { name: "Travel", value: "TRAVEL" },
  { name: "Insurance", value: "INSURANCE" },
  { name: "Investment", value: "INVESTMENT" },
  {name: "Goods purchased", value: "GOODS_PURCHASE"},
];

export const paymentMethodConfigurations = {
  unionpay: {
    serviceCode: "UNIONPAY",
    service: "BANK_CARD",
    fundSource: "CASH",
    transferType: "C2C",
  },
  alipay: {
    serviceCode: "ALIPAY",
    service: "WALLET",
    fundSource: "CASH",
    transferType: "C2C",
  },
  wechatpay: {
    serviceCode: "WECHATPAY",
    service: "WALLET",
    fundSource: "CASH",
    transferType: "C2C",
  },
  bank_account_individual: {
    service: "BANK_ACCOUNT",
    fundSource: "CASH",
    transferType: "C2C",
  },
  bank_account_business: {
    service: "BANK_ACCOUNT",
    fundSource: "CASH",
    transferType: "B2B",
  },
};

export const CNYIDTypes = [
  { label: "National ID card", value: "ID_CARD" },
  {
    label: "Unified Social Credit ID of China registered company",
    value: "UNIFIED_SOCIAL_CREDIT_ID",
  },
  { label: "Passport", value: "PASSPORT" },
  { label: "Business registration cert", value: "BUSINESS_REGISTRATION_CERT" },
];

export const AttachmentFileType = [
  { label: "Commercial Documents", value: "COMMERCIAL_DOCUMENTS" },
  { label: "Logistics Documents", value: "LOGISTICS_DOCUMENTS" },
  {
    label: "Logistics Communication Documents",
    value: "LOGISTICS_COMMUNICA TION_DOCUMENTS",
  },
  { label: "Contract Documents", value: "CONTRACT_DOCUMENTS" },
  { label: "Order Documents", value: "ORDER_DOCUMENTS" },
  { label: "Custom Declaration", value: "CUSTOMS_DECLARATION" },
  { label: "Other", value: "OTHERS" },
];

export const Relationships = [
  { label: "Self", value: "SELF" },
  { label: "Spouse", value: "SPOUSE" },
  { label: "Parents", value: "PARENTS" },
  { label: "Sons and Daughters", value: "SONS_AND_DAUGHTERS" },
  { label: "Brothers and Sisters", value: "BROTHERS_AND_SISTERS" },
  { label: "Grandparents", value: "GRANDPARENTS" },
  { label: "Grandparents-in-Law", value: "GRANDPARENTS_IN_LAW" },
  { label: "Grandchildren", value: "GRANDCHILDREN" },
  { label: "Maternal Grandchildren", value: "MATERNAL_GRANDCHILDREN" },
];


export const BENEFICIARY_OPTIONS = [
  { label: "Individual", value: "individual", type: "individual" },
  { label: "Business", value: "company", type: "business" },
];