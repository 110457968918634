export const noOfTxnsOptions = [
    { label: "1 - 100", value: "1 - 100" },
    { label: "100 - 500", value: "100 - 500" },
    { label: "500 - 1000", value: "500 - 1000" },
    { label: "1000 - above", value: "1000 - above" }
]

export const valueOfTxnsOptions = [
    { label: "$1 - $1000", value: "$1 - $1000" },
    { label: "$1001 - $10,000", value: "$1001 - $10000" },
    { label: "$50,001 - $100,000", value: "$50001 - $100000" },
    { label: "$100,001 - $500,000", value: "$100001 - $500000" },
    { label: "$500,001 - $1,000,000", value: "$500001 - $1000000" },
    { label: "$1,000,001 - $10,000,000", value: "$1000001 - $10000000" },
    { label: "above $10,000,000", value: "above $10000000" }
]

export const polarResponse = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
]

type DocumentType = {
    id: string;
    title: string;
    description: string;
    path: string;
    isRequired: boolean;
  };
  
  export const listOfDirectorsDocuments: DocumentType[] = [
    {
      id: 'photo',
      title: "Add director's photograph",
      description: "Upload a clear photograph of the director",
      path: "director-photo",
      isRequired: true,
    },
    {
      id: 'id',
      title: "Director's ID",
      description: "This should be a government issued ID such as a driver's license or international passport, with at least 6 months validity period.",
      path: "director-id",
      isRequired: true,
    },
    {
      id: 'address',
      title: "Proof of Address of Director and/or UBO",
      description: "This should be a recognised document detailing director or UBO's residential address.",
      path: "director-proof-of-address",
      isRequired: true,
    }
  ]


  export const documentSections = [
    {
        title: "Proof of Address",
        description: "This could be utility bills showing your business address (not older than 3 months) such as an: electricity bill, waste management bill, water, gas, or cable TV bills i.e. DSTV or Tax assessments Letter from a public authority such as affidavits or court notices.",
        componentId: 6,
        path: 'proof-of-address',
        isRequired: true,
    },
    {
        title: "Operating License (Optional)",
        description: "Upload any relevant operating license obtained related to your business industry.",
        componentId: 44,
        path: 'account-statement',
        isRequired: false,
    },
    {
        title: "Certificate of Incorporation",
        description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
        componentId: 10,
        path: 'certificate-of-incorporation',
        isRequired: true,
    },
    {
        title: "Memorandum and Articles of Association",
        description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
        componentId: 12,
        path: 'memorandum-articles-of-association',
        isRequired: true,
    },
    {
        title: "Business Name Registration Certificate",
        description: "Sed ut perspiciatis unde omnis iste natus error sit voluptbusinessNameRegCert...",
        componentId: 14,
        path: 'business-name-reg-cert',
        isRequired: true,
    },
    {
        title: "CAC Status Report",
        description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium...",
        componentId: 11,
        path: 'cac-status-report',
        isRequired: true,
    },
    {
        title: "Registered Aims and Objectives",
        description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
        componentId: 48,
        path: 'registered-aims-objectives',
        isRequired: true,
    },
    {
        title: "CAC Application Form",
        description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
        componentId: 15,
        path: 'cac-application-form',
        isRequired: true,
    },
];