import React, { useContext, useEffect, useState } from "react";
import { BusinessTitle, BusinessText, ErrorText, BusinessSectionTitle, BizDocBizTypeSelect, Divider, BusinessSecondTitle, DirectorDetailsContainer, InnerContainerDirectorDocs, OnboardingButtonContainer, OverallContainer, PageHeaderWrapper } from "../styles";
import Loader from "../../../components/common/Loader";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import FormSection from "../components/FormSection";
import { Controller, useForm } from "react-hook-form";
import { useBusinessTypes } from "../../../app/hooks/useBusinessTypes";
import { Types } from "../Types";
import { useFileUpload } from "../../../app/hooks/useFileUpload";
import FileUpload from "../components/FileUpload";
import CustomSelectTwo from "../../../components/common/CustomSelect/CustomSelectTwo";
import { Button } from "../../../components/common/Button";
import { addDirectorDocuments, addRequiredBusinessDocuments, deleteDirector, getListOfDirectors, getParticularStep } from "../redux/actions";
import { useRequiredDocs } from "../../../app/hooks/useRequiredDocs";
import { documentSections } from "../utils/constants";
import { listOfDirectorsDocuments } from "../utils/constants";
import { updateBusinessType, updateDirectorsList, updateUploadedBusinessDocs } from "../redux/slice";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import { extractFileNameFromUrl, getDirectorDocumentState, handleDirectorFileDelete, handleDirectorFileSelect, isDocumentRequired } from "../utils/businessDocumentUtils";
import { useWindowSize } from "@reach/window-size";
import { getUploadedDocumentsByBusinessId } from "../../Compliance/complianceSlice";

export type DirectorDocumentState = {
  file: File | null;
  fileUrl: string | null;
  uploading: boolean;
  error: string | null;
};

type Props = {
  handleNext: () => void;
  handlePrevious: () => void;
};

export type BusinessDocUploadState = {
  id: number;
  uploadStatus: boolean;
  name: string;
}

const BusinessDocuments = ({ handleNext, handlePrevious }: Props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm<Types>({ 
    mode: 'onChange',
    defaultValues: {
      businessType: null,
    }
   });

  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const { businessTypes } = useBusinessTypes();
  const { loading } = useAppSelector((state) => state.onboarding || {});
  const { businessTypeId, directorsList, uploadedBusinessDocs } = useAppSelector((state) => state.onboarding)
  const { businessId } = useAppSelector((state) => state.users);
  const [ selectedBusinessType, setSelectedBusinessType ] = useState('')
  const { requiredDocs } = useRequiredDocs({ businessTypeId })
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext,
  );

  useEffect(() => {
    const getSelectedBusinessType = () => {
      const selectedBizType = businessTypes.find((type) => type.value === businessTypeId)
      setSelectedBusinessType(selectedBizType)
    }
    getSelectedBusinessType()
  }, [businessTypeId, businessTypes])

  const sendBusinessDocToBE = async (uploadOrDelete: boolean, docUrl: string, docId: number, docFileName: string) => {
    const individualDocPayload = {
      businessDocument: docId,
      business: businessId,
      url: docUrl,
      approved: true,
    }

    const action = await dispatch(addRequiredBusinessDocuments(individualDocPayload))

    const currentDocs = Array.isArray(uploadedBusinessDocs)
      ? [...uploadedBusinessDocs]
      : [];

    if (addRequiredBusinessDocuments.fulfilled.match(action)) {
      if (!uploadOrDelete) {
        const updatedDocs = currentDocs.filter(doc => doc.id !== docId);
        dispatch(updateUploadedBusinessDocs(updatedDocs));
      } else {
        const newDoc: BusinessDocUploadState = {
          id: docId,
          uploadStatus: true,
          name: docFileName
        };

        const existingDocIndex = currentDocs.findIndex(doc => doc.id === docId);

        let updatedDocs;
        if (existingDocIndex !== -1) {
          updatedDocs = currentDocs.map((doc, index) =>
            index === existingDocIndex ? newDoc : doc
          );
        } else {
          updatedDocs = [...currentDocs, newDoc];
        }

        dispatch(updateUploadedBusinessDocs(updatedDocs));
      }
    } else if (addRequiredBusinessDocuments.rejected.match(action)) {
      const failedDoc: BusinessDocUploadState = {
        id: docId,
        uploadStatus: false,
        name: ''
      };

      const existingDocIndex = currentDocs.findIndex(doc => doc.id === docId);

      let updatedDocs;
      if (existingDocIndex !== -1) {
        updatedDocs = currentDocs.map((doc, index) =>
          index === existingDocIndex ? failedDoc : doc
        );
      } else {
        updatedDocs = [...currentDocs, failedDoc];
      }

      dispatch(updateUploadedBusinessDocs(updatedDocs));

      onShowErrorAlert("", String(action.payload));
    }

  }

  const fileStates = documentSections.map(() => useFileUpload());
  const [sectionFileUrls, setSectionFileUrls] = useState<Record<string, any>>({});

  const sectionsWithFileUpload = documentSections.map((section, index) => {

    const handleFileSelect = (selectedFile: File) => {
      const currentFileState: any = fileStates[index];
      currentFileState.setFile(selectedFile);
      currentFileState.uploadFile(selectedFile, section.path)
        .then((downloadUrl) => {
          setSectionFileUrls(prev => ({
            ...prev,
            [section.title]: { url: downloadUrl, docId: section.componentId },
          }));
          sendBusinessDocToBE(true, downloadUrl, section.componentId, selectedFile.name)
        });
    };

    const handleFileDelete = () => {
      const currentFileState = fileStates[index];
      currentFileState.setFile(null);
      currentFileState.setFileUrl(null);
      setSectionFileUrls(prev => {
        const updated = { ...prev };
        delete updated[section.title];
        return updated;
      });
      sendBusinessDocToBE(false, '', section.componentId, '')
    };

    return {
      ...section,
      fileState: fileStates[index],
      handleFileSelect,
      handleFileDelete,
      shouldDisplay: isDocumentRequired(section.componentId, requiredDocs)
    };
  });

  const directorFileUploadLogic = useFileUpload()
  const [directorDocumentStates, setDirectorDocumentStates] = useState<
    Record<number, Record<string, DirectorDocumentState>>
  >({});
  
  const handleBusinessDocSubmit = async () => {
    let missingBusinessDocs
    let missingDirectorDocs

    try {
        missingBusinessDocs = sectionsWithFileUpload
          ?.filter(section => section?.shouldDisplay && section?.isRequired && !sectionFileUrls[section?.title])
          ?.map(section => section?.title) || [];

        missingDirectorDocs = directorsList?.flatMap((dir) =>
          listOfDirectorsDocuments
            ?.filter(doc => doc?.isRequired && !getDirectorDocumentState(dir?.id, doc?.id, directorDocumentStates).fileUrl)
            ?.map(doc => doc?.title)
        ) || [];

        const allMissingDocs = [...missingBusinessDocs, ...missingDirectorDocs];
        
        if (allMissingDocs?.length > 0) {
          onShowErrorAlert("error", (`Required: ${allMissingDocs[0]} ${allMissingDocs.length > 1 ? 'and others' : ''}`));
          return
        }

        const directorDocumentPayload = directorsList.map((dir) => ({
          ...dir,
          poaUrl: directorDocumentStates[dir.id]?.address?.fileUrl,
          idUrl: directorDocumentStates[dir.id]?.id?.fileUrl,
          photographUrl: directorDocumentStates[dir.id]?.photo?.fileUrl,
        }))

        const directorDocsAction = await dispatch(addDirectorDocuments(directorDocumentPayload))
        
        if (addDirectorDocuments.fulfilled.match(directorDocsAction)) {
          handleNext()
        } else if (addDirectorDocuments.rejected.match(directorDocsAction)) {
          onShowErrorAlert("", String(directorDocsAction.payload));
        }

    } catch (error) {
      onShowErrorAlert('', 'There is an error. Please reload the page.');
    }
  }

  const getSavedBusinessDocs = async () => {
    const stepNumber = 5
    const action = await dispatch(getParticularStep ({businessId, stepNumber}))
    const allDirectors = await dispatch(getListOfDirectors(businessId))

    if(getParticularStep.fulfilled.match(action)) {
      dispatch(updateBusinessType(action?.payload?.businessType as any))

      const uploadedDocsAction = await dispatch(getUploadedDocumentsByBusinessId({businessId}))
      
      if (getUploadedDocumentsByBusinessId.fulfilled.match(uploadedDocsAction)) {
        const uploadedDocs = uploadedDocsAction?.payload
        
        const updatedSectionFileUrls = {}
        uploadedDocs?.forEach(doc => {
          const section = documentSections.find(s => s.componentId === doc.businessDocument.id)
          if (section) {
            updatedSectionFileUrls[section.title] = { 
              url: doc.url, 
              docId: doc.businessDocument.id 
            }
            
            const sectionIndex = documentSections.findIndex(s => s.componentId === doc.businessDocument.id)
            if (sectionIndex !== -1) {
              const currentFileState = fileStates[sectionIndex]
              currentFileState.setFileUrl(doc.url)
              
              const fileName = extractFileNameFromUrl(doc.url)
              if (fileName) {
                const file = new File([], fileName, { type: 'image/jpeg' })
                currentFileState.setFile(file)
              }
            }
          }
        })
        
        setSectionFileUrls(updatedSectionFileUrls)
        dispatch(updateUploadedBusinessDocs(uploadedDocs))
      }
    } else if (getParticularStep.rejected.match(action)) {
      console.error('We could not retrieve previously saved data.');
    }

    if(getListOfDirectors.fulfilled.match(allDirectors)) {
      dispatch(updateDirectorsList(allDirectors.payload))

      const newDirectorDocumentStates = {}
      allDirectors?.payload?.forEach(director => {
        newDirectorDocumentStates[director.id] = {}
        
        listOfDirectorsDocuments.forEach(docType => {
          let fileUrl = null
          switch(docType.id) {
            case 'address':
              fileUrl = director.poaUrl
              break
            case 'id':
              fileUrl = director.idUrl
              break
            case 'photo':
              fileUrl = director.photographUrl
              break
          }

          if (fileUrl) {
            const fileName = extractFileNameFromUrl(fileUrl)
            const file = fileName ? new File([], fileName, { type: 'image/jpeg' }) : null
            
            newDirectorDocumentStates[director.id][docType.id] = {
              file,
              fileUrl,
              uploading: false,
              error: null
            }
          }
        })
      })
      setDirectorDocumentStates(newDirectorDocumentStates)
    } else if (getListOfDirectors.rejected.match(allDirectors)) {
      console.error('We could not retrieve previously saved directors list.');
    }
  }
  
  useEffect(() => {
    getSavedBusinessDocs()
  }, [])

  return (
    <OverallContainer>
      <Loader isLoading={loading} />
      <PageHeaderWrapper>
        <BusinessTitle>Business documents</BusinessTitle>
        <BusinessText>
          We need your business registration documents. This is for regulatory compliance purposes
        </BusinessText>
      </PageHeaderWrapper>
      <BizDocBizTypeSelect>
        <FormSection>
          <Controller
            name="businessType"
            control={control}
            render={({ field }) => (
              <CustomSelectTwo
                {...field}
                animatedLabel="Type of business"
                $width="100%"
                $height="48px"
                lightBorder={true}
                value={selectedBusinessType}
                disabled
              />
            )}
          />
          {errors.businessType?.type === "required" && (
            <ErrorText>
              <span>Field is required</span>
            </ErrorText>
          )}
        </FormSection>
      </BizDocBizTypeSelect>

      <div>
        {sectionsWithFileUpload?.map((section) =>
          section?.shouldDisplay && (
            <div key={section?.title}>
              <BusinessSectionTitle>{section?.title}</BusinessSectionTitle>
              <BusinessText alignLeft={true}>{section?.description}</BusinessText>
              <FormSection>
                <FileUpload
                  file={section?.fileState?.file ||
                    (() => {
                      if (!Array.isArray(uploadedBusinessDocs)) return false;
                      const uploadedDoc = uploadedBusinessDocs.find(doc => doc.id === section.componentId);
                      return uploadedDoc ? uploadedDoc : false;
                    })()
                  }
                  fileUrl={section?.fileState?.fileUrl}
                  uploading={section?.fileState?.uploading ||
                    (() => {
                      if (!Array.isArray(uploadedBusinessDocs)) return undefined;
                      const uploadedDoc = uploadedBusinessDocs.find(doc => doc.id === section?.componentId);
                      return uploadedDoc?.uploadStatus === true ? false : undefined;
                    })()
                  }
                  error={section?.fileState?.error}
                  onFileSelect={section?.handleFileSelect}
                  onFileDelete={section?.handleFileDelete}
                  uploadPath={section?.path}
                  title="Upload file"
                  subtitle="JPEG or PNG less than 5MB"
                />
              </FormSection>
            </div>
          )
        )}
      </div>

      <Divider></Divider>

      <DirectorDetailsContainer>
        <BusinessTitle>Director&apos;s and UBO(s) details</BusinessTitle>
        <BusinessText>
          We need to to identify your Directors. Please provide requested documents
        </BusinessText>

        {directorsList?.map((dir, directorIndex) => (
          <div key={directorIndex}>
            <BusinessSecondTitle>
              Ultimate Beneficial Owner ({dir.name})
            </BusinessSecondTitle>
            <BusinessText alignLeft={true}>(Entities with at least 20% of shares)</BusinessText>

            <InnerContainerDirectorDocs>
              {listOfDirectorsDocuments?.map((section) => {
                const documentState = getDirectorDocumentState(
                  dir.id,
                  section.id,
                  directorDocumentStates
                );

                return (
                  <div key={section.title}>
                    <BusinessSectionTitle>{section.title}</BusinessSectionTitle>
                    <BusinessText alignLeft={true}>{section.description}</BusinessText>
                    <FormSection>
                      <FileUpload
                        file={documentState?.file}
                        fileUrl={documentState.fileUrl}
                        uploading={documentState.uploading}
                        error={documentState.error}
                        onFileSelect={handleDirectorFileSelect(dir.id, section.id, section.path, setDirectorDocumentStates, directorFileUploadLogic)}
                        onFileDelete={handleDirectorFileDelete(dir.id, section.id, setDirectorDocumentStates)}
                        uploadPath={section.path}
                        title="Upload file"
                        subtitle="JPEG or PNG less than 5MB"
                      />
                    </FormSection>
                  </div>
                );
              })}
            </InnerContainerDirectorDocs>
            {(directorsList?.length !== (directorIndex + 1)) && <Divider></Divider>}
          </div>
        ))}
      </DirectorDetailsContainer>

      <OnboardingButtonContainer>
        <Button
          label="Back"
          theme="alternate"
          height="48px"
          fontSize="16px"
          width="150px"
          onClick={handlePrevious}
        />
        <Button
          onClick={handleSubmit(handleBusinessDocSubmit)}
          label="Continue"
          width={width < 1150 ? '' : '494px' }
          height="48px"
          fontSize="16px"
        />
      </OnboardingButtonContainer>
    </OverallContainer>
  );
};

export default BusinessDocuments;

