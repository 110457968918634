import React, { createContext, ReactNode, useEffect, useState } from "react";

type SuccessAlertContextType = {
    showAlert?: boolean;
    alertMessage?: string;
    onShowAlert?: (message: string) => void;
    onClose?: () => void;
};
export const SuccessAlertContext = createContext<
    SuccessAlertContextType | undefined
>(undefined);

const SuccessAlertContextProvider = ({ children }: { children: ReactNode }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        if (showAlert) {
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showAlert]);

    const onShowAlert = (message: string) => {
        setShowAlert(true);
        setAlertMessage(message);
    };

    const onClose = () => {
        setShowAlert(false);
    };

    return (
        <SuccessAlertContext.Provider
            value={{
                showAlert,
                alertMessage,
                onShowAlert,
                onClose,
            }}
        >
            {children}
        </SuccessAlertContext.Provider>
    );
};

export default SuccessAlertContextProvider;
