import React from 'react';
import { StyledSlider } from '../styles';

interface ProgressSliderProps {
  currentStep: number;
  totalSteps: number;
}

const ProgressSlider: React.FC<ProgressSliderProps> = ({ currentStep, totalSteps }) => {
  const sliderValue = (currentStep / totalSteps) * 100;

  return (
    <StyledSlider
      value={sliderValue}
      min={0}
      max={100}
      step={currentStep} 
    />
  );
};

export default ProgressSlider;