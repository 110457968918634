import { DirectorDocumentState } from "../steps/BusinessDocuments"

export const extractRequiredDocs = (data: any) => {
    return data.map((item) => ({
        id: item.businessDocument.id,
        document: item.businessDocument.document
    }))
}

export const isDocumentRequired = (componentId: number, requiredDocs: any) => {
    return requiredDocs.some(item => item.id === componentId)
}

const updateDirectorDocumentState = (
    directorId: number,
    documentId: string,
    updates: Partial<DirectorDocumentState>,
    setDirectorDocumentStates
  ) => {
    setDirectorDocumentStates((prevStates) => ({
      ...prevStates,
      [directorId]: {
        ...(prevStates[directorId] || {}),
        [documentId]: {
          ...(prevStates[directorId]?.[documentId] || {
            file: null,
            fileUrl: null,
            uploading: false,
            error: null,
          }),
          ...updates,
        },
      },
    }));
  };

 export const handleDirectorFileSelect = (
    directorId: number,
    documentId: string,
    uploadPath: string,
    setDirectorDocumentStates: any,
    directorFileUploadLogic: any,
  ) => async (file: File) => {
    updateDirectorDocumentState(directorId, documentId, {
      file,
      uploading: true,
      error: null,
    }, setDirectorDocumentStates);

    try {
      const uploadedUrl = await directorFileUploadLogic.uploadFile(file, uploadPath);
      updateDirectorDocumentState(directorId, documentId, {
        uploading: false,
        fileUrl: uploadedUrl,
      }, setDirectorDocumentStates);
    } catch (error) {
      updateDirectorDocumentState(directorId, documentId, {
        uploading: false,
        error: 'Failed to upload file',
      }, setDirectorDocumentStates);
    }
  };

  export const handleDirectorFileDelete = (
    directorId: number,
    documentId: string,
    setDirectorDocumentStates
  ) => () => {
    updateDirectorDocumentState(directorId, documentId, {
      file: null,
      fileUrl: null,
      uploading: false,
      error: null,
    }, setDirectorDocumentStates);
  };

  export const getDirectorDocumentState = (
    directorId: number,
    documentId: string,
    directorDocumentStates
  ): DirectorDocumentState => {
    return (
      directorDocumentStates[directorId]?.[documentId] || {
        file: null,
        fileUrl: null,
        uploading: false,
        error: null,
      }
    );
  };

  export const extractFileNameFromUrl = (
    url: string, 
  ): string | null => {
    try {
      const decodedUrl = decodeURIComponent(url);
      const match = decodedUrl.match(/\/([^/?]+)(\?|$)/)
      return match ? match[1] : null;
    } catch (error) {
      console.error('Error extracting file name:', error);
      return null;
    }
  };