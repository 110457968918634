import { createSlice } from "@reduxjs/toolkit";

interface RateState {
  rates: any[];
  usdRate: number;
}

const initialState: RateState = {
  rates: [],
  usdRate: 0,
};

export const rateSlice = createSlice({
  name: "rate",
  initialState,

  reducers: {
    setRates: (state, action) => {
      state.rates = action.payload;
    },
    setBaseRate: (state, action) => {
      state.usdRate = action.payload;
    },
  },
});

export const { setRates, setBaseRate } = rateSlice.actions;

export default rateSlice.reducer;
