import React, { createContext, useEffect, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { merchantBusinessDetail } from "../../pages/Dashboard/dashboardSlice";
import { getUploadedDocumentsByBusinessId } from "../../pages/Compliance/complianceSlice";
import { PendingComplaince } from "../../components/AppShell/components/Header/style";
import { useOnboardingV2Flag } from "../../app/hooks/useOnboardingV2Flag";

interface BusinessContextValue {
  businessType: string | null;
  uploadedDocuments: any[];
  businessId: string;
}

type BusinessContextProviderProps = {
  children?: ReactNode;
}

export const BusinessContext = createContext<BusinessContextValue | undefined>(
    undefined
);

export const BusinessContextProvider: React.FC<BusinessContextProviderProps> = ({
                                                                                  children,
                                                                                }) => {
  const { businessId, userId } = useAppSelector((state) => state?.users);
  const { businessType, uploadedDocuments, complianceData } = useAppSelector(
      (state) => state?.compliance
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onboardingFlag = useOnboardingV2Flag();

  useEffect(() => {
    dispatch(merchantBusinessDetail(businessId));
    dispatch(getUploadedDocumentsByBusinessId({ businessId }));
  }, [dispatch, businessId, userId]);

  const nextPath =  "/compliance";

  return (
      <BusinessContext.Provider
          value={{ businessType, uploadedDocuments, businessId }}
      >
        {userId &&
        !businessId &&
        complianceData?.startupComplianceStatus !== "Done" &&
        complianceData?.startupComplianceStatus === undefined &&
        location.pathname !== nextPath ? (
            <PendingComplaince>
              <h1>Update your account details</h1>
              <p>
                To start using Klasha Business, please{" "}
                <span onClick={() => navigate(nextPath)}>click here</span>
                to start our quick KYB process.
              </p>
            </PendingComplaince>
        ) : null}
        {children}
      </BusinessContext.Provider>
  );
};
