import styled from "styled-components";

export const Wrapper = styled.form`
  width: 100%;
  font-family: "Proxima Nova", sans-serif;
`;

export const Content = styled.div`
  width: 100%;
`;

export const Section = styled.div`
  margin-bottom: 32px;
  & > p {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 180%;
    color: #000000;
  }
  .business-logo-copy {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
  }
  .business-logo-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: left;
    color: #818691;
  }
`;

export const Options = styled.div`
  & label:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const ErrorText = styled.div`
  display: flex;
  margin-top: 4px;
  align-items: center;

  & span {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: var(--primary-pink);
    text-align: left;
    margin-left: 8px;
  }
`;

export const TextAreaContainer = styled.div`
  & textarea[class="ant-input"] {
    border: 1px solid #a3a3a3;
    border-radius: 8px;
    padding: 12px 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    font-family: "Inter";
    color: #000000;
  }

  & textarea::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #565c69 !important;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border-radius: 8px;
  height: 100%;

  & svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .uploaded-content {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    margin-top: 10px;
    margin: 0;
  }
  .uploaded-copy {
    color: #007912;
    font-weight: 400;
  }
`;

export const Space = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DocumentUploadBox = styled.div`
  border: 1px solid #E9E9E9;
  padding: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  margin: auto;
  border-radius: 8px;

  @media (max-width: 394px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

export const UploadButton = styled.button`
  display: flex;
  align-items: flex-start;
  gap: 10px;

  h4 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
  }

  span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #a39d9e;
    display: flex;
    flex-position: straght;
  }
`;
